import React from "react";
import { Route } from "react-router-dom";
import { CustomNavigation } from "router/layouts/LayoutProvider";

export const mapToolpadToReactRouter = (
  pages: CustomNavigation,
  RouteComponent: React.ComponentType<any>
) => {
  return pages.map((page) => {
    if (page.kind === "header" || page.kind === "divider") {
      return null;
    }

    if ("children" in page && page.children) {
      return page.children.map((child) => {
        if (child.kind === "header" || child.kind === "divider") {
          return null;
        }

        if (child.component) {
          const basePath = `${page.segment}/${child.segment}`;
          return (
            <>
              <Route
                path={basePath}
                element={<RouteComponent>{child.component}</RouteComponent>}
              />
              {child.slugBabyPattern && child.slugBabyComponent && (
                <Route
                  path={`${basePath}/${child.slugBabyPattern}`}
                  element={
                    <RouteComponent>{child.slugBabyComponent}</RouteComponent>
                  }
                />
              )}
            </>
          );
        }
      });
    }

    if (page.component) {
      return (
        <Route
          key={page.segment}
          path={`/${page.segment}`}
          element={<RouteComponent>{page.component}</RouteComponent>}
        />
      );
    }

    if (page.slugBabyPattern && page.slugBabyComponent) {
      return (
        <Route
          key={`${page.segment}/${page.slugBabyPattern}`}
          path={`${page.segment}/${page.slugBabyPattern}`}
          element={<RouteComponent>{page.slugBabyComponent}</RouteComponent>}
        />
      );
    }

    return null;
  });
};
