import { addDocument, updateDocument, getServerTimestamp } from "core/firestore";
import { uploadFile } from "core/storage";

export const addEngineerToProjectAssignment = async (
  clientId: string,
  engineerId: string,
  assignmentData: Omit<ProjectAssignment, 'id'>,
  file: File | null
): Promise<ProjectAssignment> => {
  const newAssignmentRef = await addDocument(`clients/${clientId}/projectAssignments`, {
    ...assignmentData,
    engineerId,
    createdAt: getServerTimestamp(),
  });

  let fileUrl: string | undefined;
  if (file) {
    fileUrl = await uploadFile(`clients/${clientId}/projectAssignments/${newAssignmentRef.id}`, file);
    await updateDocument(`clients/${clientId}/projectAssignments/${newAssignmentRef.id}`, { fileUrl });
  }

  const newAssignment: Omit<ProjectAssignment, 'id'> = {
    ...assignmentData,
    engineerId,
    fileUrl,
  };

  const newAssignmentRef2 = await addDocument(`users/${engineerId}/projectAssignments`, {
    clientId,
    projectId: assignmentData.projectId,
    assignmentId: newAssignmentRef.id,
    createdAt: getServerTimestamp(),
  });


  const newAssignmentWithId: ProjectAssignment = {
    ...newAssignment,
    id: newAssignmentRef2.id,
  };


  return newAssignmentWithId;
};

