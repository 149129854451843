import React from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  alpha,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatter,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SendIcon from '@mui/icons-material/Send';

interface TimesheetApprovalsGridProps {
  userEntries: Timesheet[];
  userName: string;
  handleApproveTimesheet?: (id: string) => void;
  handleRejectTimesheet?: (id: string) => void;
  handleSubmitForUser?: (id: string) => void;
  showActions: boolean;
  isUnsubmittedTab?: boolean;
  isArchiveTab?: boolean;
}

const TimesheetApprovalsGrid: React.FC<TimesheetApprovalsGridProps> = ({
  userEntries,
  userName,
  handleApproveTimesheet,
  handleRejectTimesheet,
  handleSubmitForUser,
  showActions,
  isUnsubmittedTab,
  isArchiveTab,
}) => {
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
    //   valueFormatter: (params: GridValueFormatter) => DateTime.fromISO(params.value).toFormat("yyyy-MM-dd"),
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 120,
    //   valueGetter: (params) => {
    //     const startTime = DateTime.fromISO(params.row.startTime).toFormat("HH:mm");
    //     const endTime = DateTime.fromISO(params.row.endTime).toFormat("HH:mm");
    //     return `${startTime} - ${endTime}`;
    //   },
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: number) => {
        const totalSeconds = Math.floor(params / 1000); // Convert ms to seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    }
  ];

  if (isArchiveTab) {
    columns.push(
      {
        field: "approvedAt",
        headerName: "Approval Date",
        flex: 1,
        minWidth: 150,
        type: "date",
        valueGetter: (params: any) => DateTime.fromISO(params.value).toJSDate(),
        // valueFormatter: (params) =>
        //   DateTime.fromJSDate(params.value as Date).toFormat("yyyy-MM-dd HH:mm"),
        // valueSetter: (params) => {
        //   const newDate = DateTime.fromJSDate(params.value as Date);
        //   return { ...params.row, date: newDate.toISODate() };
        // },
      },
      {
        field: "approvedByEmail",
        headerName: "Approved By",
        flex: 1,
        minWidth: 150,
      }
    );
  }

  if (showActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {isUnsubmittedTab ? (
            <Tooltip title="Submit for User">
              <IconButton
                onClick={() => handleSubmitForUser && handleSubmitForUser(params.row.id)}
                color="primary"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Approve">
                <IconButton
                  onClick={() => handleApproveTimesheet && handleApproveTimesheet(params.row.id)}
                  color="primary"
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  onClick={() => handleRejectTimesheet && handleRejectTimesheet(params.row.id)}
                  color="error"
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    });
  }



  if (userEntries.length > 0) {
    const minDate = DateTime.min(...userEntries.map(e => DateTime.fromISO(e.date)));
    const maxDate = DateTime.max(...userEntries.map(e => DateTime.fromISO(e.date)));
    const dateRange = `${minDate.toFormat('MMM d')} - ${maxDate.toFormat('MMM d, yyyy')}`;

    return (
      <Box sx={{ mb: 4 }}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: theme.shape.borderRadius }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {`${userName} (${dateRange})`}
          </Typography>
          <DataGridPro
            rows={userEntries}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            hideFooter
            sx={{
              border: "none",
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          />
        </Paper>
      </Box>
    );
  }
  return null;
};

export default TimesheetApprovalsGrid;
