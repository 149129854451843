import { getHeaders } from "../../getHeaders";
import { functionUrls } from "core";

export const downloadCSA = async (gcsUri: any) => {
  const res = await fetch(functionUrls.misc.downloadCSA.url, {
    method: functionUrls.misc.downloadCSA.url,
    headers: getHeaders(),
    body: JSON.stringify({ gcsUri: gcsUri }),
  });
  
  return res.json();
};
