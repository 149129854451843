import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Divider,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { logoNoBackground as logo } from 'assets';
import { takePaymentForClientInvoice } from 'core/invoices';
import { loadSingleClient } from 'core/clients';
import { useAuth } from 'contexts/AuthContext';
import { loadSingleStakeholder } from 'core/stakeholders';
interface PayInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: InvoiceData;
  accountId: string; // Add this prop for the main account ID
}

const PayInvoiceDialog: React.FC<PayInvoiceDialogProps> = ({ open, onClose, invoice, accountId }) => {
  const [accountType, setAccountType] = useState('Personal checking');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [tipPercentage, setTipPercentage] = useState('None');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { userInfo } = useAuth();

  const handlePayment = async () => {
    if (accountNumber !== confirmAccountNumber) {
      setError('Account numbers do not match');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const amountInPennies = Math.round(totalAmount * 100);

      const achTransferDetails = {
        accountId: accountId,
        amount: -amountInPennies, // Negative amount for inbound transfer
        individualName: accountHolderName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        companyEntryDescription: `INV#${invoice.invoiceNumber}`,
        invoiceId: invoice.id,
      }

      const client = await loadSingleClient(invoice.clientId);

      if (!client || !userInfo?.id) {
        setError('An unexpected error occurred...');
        return;
      }

      const stakeholder = await loadSingleStakeholder(userInfo.id);

      if (!stakeholder) {
        setError('Stakeholder not found');
        return;
      }

      const result = await takePaymentForClientInvoice({
        invoice,
        client,
        stakeholder,
        achTransferDetails,
      });

      if (result.success) {
        
        onClose();
        // You might want to add a success message or refresh the invoice status here
      } else {
        setError('Failed to process payment');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('An error occurred while processing the payment');
    } finally {
      setLoading(false);
    }
  };

  const totalAmount = invoice.items.reduce((sum, item) => sum + item.quantity * item.rate, 0);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Pay Invoice</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <img src={logo} alt="FreeTech Logo" style={{ maxWidth: '150px', height: 'auto' }} />
            </Box>
            <Typography variant="h6" gutterBottom>PAYMENT AMOUNT</Typography>
            <Typography variant="h4" color="primary" gutterBottom>${totalAmount.toFixed(2)}</Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>Edit amount</Typography>
            
            <Divider sx={{ my: 3 }} />
            
            <Typography variant="h6" gutterBottom>Pay with bank transfer</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Account type</InputLabel>
              <Select value={accountType} onChange={(e) => setAccountType(e.target.value as string)}>
                <MenuItem value="Personal checking">Personal checking</MenuItem>
                <MenuItem value="Business checking">Business checking</MenuItem>
                <MenuItem value="Savings">Savings</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Routing number"
              value={routingNumber}
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Account number"
              type="password"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm account number"
              type="password"
              value={confirmAccountNumber}
              onChange={(e) => setConfirmAccountNumber(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Account holder's name"
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
            />

            <Box mt={3}>
              <Typography variant="subtitle1" gutterBottom>Tips</Typography>
              <Typography variant="body2" gutterBottom>Show your appreciation with an optional tip.</Typography>
              <RadioGroup row value={tipPercentage} onChange={(e) => setTipPercentage(e.target.value)}>
                <FormControlLabel value="5%" control={<Radio />} label="5%" />
                <FormControlLabel value="10%" control={<Radio />} label="10%" />
                <FormControlLabel value="15%" control={<Radio />} label="15%" />
                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                <FormControlLabel value="None" control={<Radio />} label="None" />
              </RadioGroup>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box bgcolor="#f5f5f5" p={3} borderRadius={2}>
              <Typography variant="h6" gutterBottom>Invoice Details</Typography>
              <Typography variant="body1">Invoice #{invoice.invoiceNumber}</Typography>
              <Typography variant="body1">Due date: {invoice.dueDate}</Typography>
              <Typography variant="body1" gutterBottom>Invoice amount: ${totalAmount.toFixed(2)}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Total: ${totalAmount.toFixed(2)}</Typography>
              <Button variant="outlined" sx={{ mt: 2 }} fullWidth>View invoice</Button>

              <Box mt={4}>
                <Typography variant="subtitle1" gutterBottom>Merchant details</Typography>
                <Typography variant="body2">FreeTech</Typography>
                <Typography variant="body2">Email: admin@freetech.co</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handlePayment} 
          variant="contained" 
          color="primary" 
          size="large" 
          fullWidth
          disabled={loading || !accountNumber || !confirmAccountNumber || !routingNumber || !accountHolderName}
        >
          {loading ? <CircularProgress size={24} /> : `Pay $${totalAmount.toFixed(2)}`}
        </Button>
      </DialogActions>
      {error && (
        <Box px={3} pb={2}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default PayInvoiceDialog;