import { getHeaders } from "core/getHeaders";
import { encryptPayload } from "../encryptPayload";
import { increaseFunctionUrls } from "../increaseFunctionUrls";
export const listExternalAccounts = async (
  params: typeof increaseFunctionUrls.externalAccounts.listExternalAccounts.input
): Promise<typeof increaseFunctionUrls.externalAccounts.listExternalAccounts.output> => {
  

    const response = await fetch(increaseFunctionUrls.externalAccounts.listExternalAccounts.url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ payload: encryptPayload(params) })
  });

  if (!response.ok) {
    throw new Error("Failed to list external accounts");
  }

  return response.json();
};