import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const getUserEmailById = async (userId: string): Promise<string | null> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      return userData.email || null;
    } else {
      
      return null;
    }
  } catch (error) {
    console.error('Error fetching user email:', error);
    throw error;
  }
};
