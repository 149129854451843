import React, { useEffect } from "react";
import { AppProvider } from "@toolpad/core";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type {
  NavigationDividerItem,
  NavigationPageItem,
  NavigationSubheaderItem,
  Router,
  Session,
} from "@toolpad/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  Home as HomeIcon,
  Description as DescriptionIcon,
  Paid as PaidIcon,
  Group as GroupIcon,
  Person2,
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  Approval as ApprovalIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Build as ToolsIcon,
  AssignmentInd as CSAIcon,
  Timer as ClockifyIcon,
  AssignmentTurnedIn as ProjectAssignmentIcon,
  BorderColor as BorderColorIcon,
} from "@mui/icons-material";
import { useAuth } from "contexts/AuthContext";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
  SignAgreements,
} from "views/Freelancer";
import { ClientInvoices, StakeholderProjects } from "views/Stakeholder";
import {
  CSAGenerator,
  UserManagement,
  ClientManagement,
  FreelancerManagement,
  SuperAdminHome,
  InvoiceManagement,
  Bank,
  ProjectAssignmentGenerator,
  TimeTracker,
  TimesheetApprovals,
  AdminSignAgreements,
  AdminProjectAssginments,
  ClientOverview,
} from "views/SuperAdmin";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import CoreLayout from "./CoreLayout";

interface CustomNavigationPageItem extends NavigationPageItem {
  children?: CustomNavigation;
  component?: React.ReactNode;
  slugBabyPattern?: string;
  slugBabyComponent?: React.ReactNode;
}

type CustomNavigationItem =
  | CustomNavigationPageItem
  | NavigationSubheaderItem
  | NavigationDividerItem;

export type CustomNavigation = CustomNavigationItem[];

export const superuserPages: CustomNavigation = [
  { kind: "header", title: "General" },
  {
    segment: "admin",
    title: "Home",
    icon: <HomeIcon />,
    component: <SuperAdminHome />,
  },
  {
    segment: "project-assignments",
    title: "Project Assignments",
    icon: <AssignmentIcon />,
    component: <ProjectAssignments />,
  },
  {
    segment: "freelancer-manage",
    title: "Profile",
    icon: <Person2 />,
    component: <Manage />,
  },
  {
    segment: "client-invoices",
    title: "Invoices",
    icon: <ReceiptIcon />,
    component: <ClientInvoices />,
  },
  {
    segment: "admin/sign-agreements",
    title: "Admin Sign Agreements",
    icon: <DescriptionIcon />,
    component: <AdminSignAgreements />,
  },
  {
    segment: "sign-agreements",
    title: "Freelancer Sign Agreements",
    icon: <DescriptionIcon />,
    component: <SignAgreements />,
  },
  {
    segment: "admin/project-assignments",
    title: "Project Assignments",
    icon: <AssignmentIcon />,
    component: <AdminProjectAssginments />,
  },
  {
    segment: "timesheet",
    title: "Time Tracker",
    icon: <AccessTimeIcon />,
    component: <TimeTracker />,
  },
  {
    segment: "admin/timesheet-approvals",
    title: "Timesheet Approvals",
    icon: <ApprovalIcon />,
    component: <TimesheetApprovals />,
  },
  { kind: "divider" },
  { kind: "header", title: "Management" },
  {
    segment: "admin",
    title: "Operations",
    icon: <BusinessIcon />,
    children: [
      {
        segment: "client-management",
        title: "Manage Clients",
        icon: <PeopleIcon />,
        slugBabyPattern: ":clientId",
        slugBabyComponent: <ClientOverview />,
        component: <ClientManagement />,
      },
      {
        segment: "freelancer-management",
        title: "Manage Freelancers",
        icon: <PeopleIcon />,
        slugBabyPattern: ":freelancerId",
        slugBabyComponent: <FreelancerOverview />,
        component: <FreelancerManagement />,
      },
      {
        segment: "user-management",
        title: "Manage Users",
        icon: <GroupIcon />,
        component: <UserManagement />,
      },
    ],
  },

  {
    segment: "admin",
    title: "Finance",
    icon: <AccountBalanceIcon />,
    children: [
      {
        segment: "vault",
        title: "Vault",
        icon: <PaidIcon />,
        component: <Bank />,
      },
      {
        segment: "invoice-management",
        title: "Invoicing",
        icon: <MonetizationOnIcon />,
        component: <InvoiceManagement />,
      },
    ],
  },
  {
    segment: "admin",
    title: "Tools",
    icon: <ToolsIcon />,
    children: [
      {
        segment: "csa-generator",
        title: "CSA Generator",
        icon: <CSAIcon />,
        component: <CSAGenerator />,
      },
      {
        segment: "project-assignment-generator",
        title: "Project Assignment Generator",
        icon: <ProjectAssignmentIcon />,
        component: <ProjectAssignmentGenerator />,
      },
    ],
  },
];

export const freelancerPages: CustomNavigation = [
  { kind: "header", title: "General" },
  {
    segment: "freelancer",
    title: "Home",
    icon: <HomeIcon />,
    component: <FreelancerHome />,
  },
  {
    segment: "freelancer-manage",
    title: "Profile",
    icon: <Person2 />,
    component: <Manage />,
  },
  {
    segment: "timesheet",
    title: "Time Tracker",
    icon: <AccessTimeIcon />,
    component: <TimeTracker />,
  },
  {
    segment: "project-assignments",
    title: "Project Assignments",
    icon: <AssignmentIcon />,
    component: <ProjectAssignments />,
  },
  {
    segment: "sign-agreements",
    title: "Sign Agreements",
    icon: <BorderColorIcon />,
    component: <SignAgreements />,
  },
];

export const stakeholderPages: CustomNavigation = [
  { kind: "header", title: "General" },
  //   {
  //     segment: "",
  //     title: "Home",
  //     icon: <HomeIcon />,
  //   },
  {
    segment: "stakeholder/invoices",
    title: "Invoices",
    icon: <ReceiptIcon />,
    component: <ClientInvoices />,
  },
  {
    segment: "stakeholder/projects",
    title: "Projects",
    icon: <AssignmentIcon />,
    component: <StakeholderProjects />,
  },
];

export const LayoutProvider = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const router = React.useMemo<Router>(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: (path) => navigate(path),
    }),
    [location, navigate]
  );

  const { userInfo, logout, isLoading } = useAuth();

  useEffect(() => {
    if (userInfo) {
      setSession({
        user: {
          id: userInfo?.id,
          name: userInfo?.firstName + " " + userInfo?.lastName,
          email: userInfo?.email,
          image: userInfo?.profilePicture,
        },
      });
    }
  }, [userInfo]);

  const userType = userInfo?.user_type;

  const pages = React.useMemo(() => {
    switch (userType) {
      case "admin":
        return superuserPages;
      case "freelancer":
        return freelancerPages;
      case "stakeholder":
        return stakeholderPages;
      default:
        return [];
    }
  }, [userType]);

  const [session, setSession] = React.useState<Session | null>({
    user: {
      id: userInfo?.id,
      name: userInfo?.firstName + " " + userInfo?.lastName,
      email: userInfo?.email,
      image: userInfo?.profilePicture,
    },
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            id: userInfo?.id,
            name: userInfo?.firstName + " " + userInfo?.lastName,
            email: userInfo?.email,
            image: userInfo?.profilePicture,
          },
        });
      },
      signOut: () => {
        setSession(null);
        logout();
        navigate("/login");
      },
    };
  }, [userInfo, logout]);

  return (
    <AppProvider
      authentication={authentication}
      session={session}
      navigation={pages}
      theme={theme}
      router={router}
      defaultSidebarCollapsed={true}
      branding={{
        logo: (
          <Box sx={{ paddingLeft: 2 }}>
            <Box
              component="img"
              src={
                "https://raw.githubusercontent.com/the-freetech-company/freetech-image-content/refs/heads/main/freeteh-cropped-larger.png"
              }
              alt="FreeTech"
              sx={{
                maxWidth: {
                  xs: "200px", // for extra small screens
                  sm: "250px", // for small screens
                  md: "300px", // for medium screens
                  lg: "350px", // for large screens
                  xl: "400px", // for extra large screens
                },
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Box>
        ),
        title: "",
      }}
    >
      <CoreLayout>
        <Outlet />
      </CoreLayout>
    </AppProvider>
  );
};
