import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  collection,
  getDocs,
  query,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { db } from "config/firebase";

const clientConverter: FirestoreDataConverter<Client> = {
  toFirestore(client: WithFieldValue<Client>): DocumentData {
    const { id, ...data } = client;
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Client {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      companyName: data.companyName,
      pointOfContactName: data.pointOfContactName,
      email: data.email,
      phone: data.phone,
      location: data.location,
      size: data.size,
      status: data.status,
      introductionDate: data.introductionDate,
    } as Client;
  }
};

// Load all clients without subcollections
const loadAllClients = async (): Promise<Client[]> => {
  const q = query(collection(db, "clients").withConverter(clientConverter));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data());
};

// CRUD hooks for clients
export const useAdminClients = () => {
  const queryClient = useQueryClient();

  
  // Load all clients (GET)
  const { data: clients, isLoading, isError }: UseQueryResult<Client[], Error> = useQuery<Client[], Error>({
    queryKey: ["clients"],
    queryFn: loadAllClients,
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Add new client (POST)
  const addClient: UseMutationResult<DocumentReference, Error, Partial<Client>> = useMutation<DocumentReference, Error, Partial<Client>>({
    mutationFn: async (newClient: Partial<Client>) => {
      const clientData: Client = {
        ...newClient,
        id: newClient.id || '',
        companyName: newClient.companyName!,
        pointOfContactName: newClient.pointOfContactName!,
        email: newClient.email!,
        phone: newClient.phone!,
        location: newClient.location!,
        size: newClient.size!,
        status: newClient.status || 'opportunity',
        introductionDate: newClient.introductionDate || new Date().toISOString(),
      };
      const clientsRef = collection(db, "clients").withConverter(clientConverter);
      return await addDoc(clientsRef, clientData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Update a client (PUT)
  const updateClient: UseMutationResult<void, Error, { id: string; client: Partial<Client> }> = useMutation<void, Error, { id: string; client: Partial<Client> }>({
    mutationFn: async ({ id, client }: { id: string; client: Partial<Client> }) => {
      const clientRef = doc(db, "clients", id).withConverter(clientConverter);
      await updateDoc(clientRef, client);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Delete a client (DELETE)
  const deleteClient: UseMutationResult<void, Error, string> = useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      await deleteDoc(doc(db, "clients", id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  return {
    clients,
    isLoading,
    isError,
    addClient,
    updateClient,
    deleteClient,
  };
};
