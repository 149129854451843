import { storage } from "config/firebase";
import { ref, getDownloadURL } from "firebase/storage";

export const getProjectAssignmentBlobUrl = async (
  clientId: string,
  assignmentId: string
): Promise<string | null> => {
  try {
    const filePath = `clients/${clientId}/projectAssignments/${assignmentId}`;
    const fileRef = ref(storage, filePath);
    const url = await getDownloadURL(fileRef);
    return url;
  } catch (error) {
    return null;
  }
};
