import {
  doc,
  getDoc,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { getUserProfile } from "../users/getUserProfile";

const db = getFirestore();

export const loadSingleClient = async (
  clientId: string
): Promise<Client | null> => {
  const docRef = doc(db, "clients", clientId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const clientData: Client = { id: docSnap.id, ...docSnap.data() } as Client;

    // Fetch stakeholders from the subcollection
    const stakeholdersSnapshot = await getDocs(collection(docRef, "stakeholders"));
    const stakeholdersData = await Promise.all(
      stakeholdersSnapshot.docs.map(async (doc) => {
        const userData = await getUserProfile(doc.data().userId);
        return userData ? { userId: userData.id, associationDate: doc.data().associationDate } : null;
      })
    );
    clientData.stakeholders = stakeholdersData.filter((stakeholder): stakeholder is { userId: string; associationDate: string } => stakeholder !== null) as any;

    // Fetch freelancers (engineers) from the subcollection
    const freelancersSnapshot = await getDocs(collection(docRef, "freelancers"));
    const freelancersData = freelancersSnapshot.docs.map(doc => ({
      userId: doc.id,
      associationDate: doc.data().associationDate,
      projects: doc.data().projects
    }));
    clientData.engineers = freelancersData as any;

    return clientData;
  } else {
    
    return null;
  }
};
