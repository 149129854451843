import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InvoiceForm from './InvoiceForm';

interface GenerateInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (invoiceData: InvoiceData) => void;
}

const GenerateInvoiceDialog: React.FC<GenerateInvoiceDialogProps> = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Generate Invoice
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <InvoiceForm onSubmit={(data) => {
          onSubmit(data);
          onClose();
        }} />
      </DialogContent>
    </Dialog>
  );
};

export default GenerateInvoiceDialog;