import { getDocument, updateDocument } from "core/firestore";
import { uploadFile } from "core/storage";

export const updateProjectAssignment = async (
  clientId: string,
  assignmentId: string,
  updatedAssignment: Partial<ProjectAssignment>,
  file?: File | null
): Promise<ProjectAssignment> => {
  const cleanedAssignment = Object.entries(updatedAssignment).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key as keyof ProjectAssignment] = value as any;
    }
    return acc;
  }, {} as Partial<ProjectAssignment>);

  if (file) {
    try {
      // Upload the file to Firebase Storage
      const fileUrl = await uploadFile(`clients/${clientId}/projectAssignments/${assignmentId}`, file);
      
      // Add the file URL to the cleaned assignment
      cleanedAssignment.fileUrl = fileUrl;
      
      
    } catch (error) {
      console.error("Error uploading file:", error);
      throw new Error("Failed to upload file");
    }
  }
  
  // Update the document in Firestore
  await updateDocument(`clients/${clientId}/projectAssignments/${assignmentId}`, cleanedAssignment);

  // Fetch the updated document
  const updatedDoc = await getDocument(`clients/${clientId}/projectAssignments/${assignmentId}`);

  if (!updatedDoc) {
    throw new Error("Failed to fetch updated document");
  }

  return updatedDoc as ProjectAssignment;
};
