import React from "react";
import { DashboardLayout } from "@toolpad/core";
import { Box } from "@mui/material";
import Footer from "components/Footer";

// const ToolbarActions: React.FC = () => {
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//       <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
//         <TimerDisplay />
//       </Box>
//     </Box>
//   );
// };

export const CoreLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <DashboardLayout
      // slots={{
      //   toolbarActions: ToolbarActions,
      // }}
      defaultSidebarCollapsed={true}
      slotProps={{
        toolbarAccount: {
          slotProps: {
            signInButton: {
              sx: { display: "none" },
            },
          },
        },
      }}
    >
      <Box
        maxWidth="xl"
        sx={{
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: 2,
          paddingRight: 2,
          minHeight: "80vh",
        }}
      >
        {children}
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default CoreLayout;
