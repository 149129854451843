import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { collection, query, getDocs, getFirestore, where } from "firebase/firestore";

interface AddStakeholderDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (stakeholderData: Omit<ClientStakeholder, "email" | "user_type">) => Promise<void>;
  clientId: string;
  currentStakeholders: Array<{ userId: string }>;
}

const AddStakeholderDialog: React.FC<AddStakeholderDialogProps> = ({
  open,
  onClose,
  onAdd,
  clientId,
  currentStakeholders,
}) => {
  const [stakeholders, setStakeholders] = useState<UserInfoType[]>([]);

  useEffect(() => {
    const fetchStakeholders = async () => {
      const db = getFirestore();
      const stakeholdersRef = collection(db, "users");
      const stakeholdersQuery = query(stakeholdersRef, where("user_type", "==", "stakeholder"));
      const stakeholdersSnapshot = await getDocs(stakeholdersQuery);
      const stakeholdersList = stakeholdersSnapshot.docs
        .map((doc) => ({ ...doc.data() as UserInfoType, id: doc.id, userId: doc.id }))
        .filter((stakeholder) => !currentStakeholders.some((cs) => cs.userId === stakeholder.userId));
      setStakeholders(stakeholdersList);
    };

    if (open) {
      fetchStakeholders();
    }
  }, [open, currentStakeholders]);

  const handleAdd = (stakeholderId: string) => {
    onAdd({ 
      id: stakeholderId, 
      associationDate: new Date().toISOString() 
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Stakeholder</DialogTitle>
      <DialogContent>
        <List>
          {stakeholders.map((stakeholder) => (
            <ListItem key={stakeholder.id}>
              <ListItemText
                primary={`${stakeholder.firstName} ${stakeholder.lastName}`}
                secondary={stakeholder.email}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => handleAdd(stakeholder.id)}
                >
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStakeholderDialog;