type SortableObject = { [key: string]: any };

function isDate(value: any): boolean {
  if (value instanceof Date) return true;
  if (typeof value !== 'string') return false;
  
  // Try parsing the string as a date
  const parsed = Date.parse(value);
  return !isNaN(parsed);
}

export function sortObjectArrayByColumn<T extends SortableObject>(
  array: T[],
  column: keyof T,
  direction: 'asc' | 'desc' = 'desc'
): T[] {

  return [...array].sort((a, b) => {
    const valueA = a[column];
    const valueB = b[column];

    if (valueA === valueB) return 0;

    if (valueA === null || valueA === undefined) return direction === 'asc' ? -1 : 1;
    if (valueB === null || valueB === undefined) return direction === 'asc' ? 1 : -1;

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return direction === 'asc' 
        ? valueA.localeCompare(valueB) 
        : valueB.localeCompare(valueA);
    }

    if (isDate(valueA) && isDate(valueB)) {
      return direction === 'asc' 
        ? valueA.getTime() - valueB.getTime() 
        : valueB.getTime() - valueA.getTime();
    }

    return direction === 'asc' 
      ? (valueA < valueB ? -1 : 1)
      : (valueB < valueA ? -1 : 1);
  });
}


