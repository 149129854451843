import React from 'react';
import { Card, CardContent, Typography, Box, Chip } from '@mui/material';

interface StakeholderProjectCardProps {
  project: Project;
}

const StakeholderProjectCard: React.FC<StakeholderProjectCardProps> = ({ project }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {project.name}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Chip 
            label={project.status} 
            color={project.status === 'idea' || project.status === 'active' || project.status === 'completed' || project.status === 'on_hold' || project.status === 'cancelled' ? 'success' : 'default'}
          />
          {project.startDate && (
            <Typography variant="body2" color="text.secondary">
              Start: {new Date(project.startDate).toLocaleDateString()}
            </Typography>
          )}
        </Box>
        {project.description && (
          <Typography variant="body2" color="text.secondary">
            {project.description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default StakeholderProjectCard;
