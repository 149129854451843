import { useQuery } from "@tanstack/react-query";
import { getDocuments } from "core/firestore";

export interface FreelancerStatistics {
  totalAssignments: {
    label: string;
    value: number;
  };
  activeAssignments: {
    label: string;
    value: number;
  };
  completedAssignments: {
    label: string;
    value: number;
  };
  totalUtilizedHours: {
    label: string;
    value: number;
  };
  totalEarnings: {
    label: string;
    value: number;
  };
  averageHourlyRate: {
    label: string;
    value: number;
  };
  averagePerformanceRating: {
    label: string;
    value: number;
  };
}

export const getFreelancerStatisticsForClient = async (clientId: string, freelancerId: string): Promise<FreelancerStatistics> => {
  try {
    // Get all project assignments for the freelancer with this client
    const projectAssignments = await getDocuments<ProjectAssignment>(
      `clients/${clientId}/projectAssignments`,
      ['engineerId', '==', freelancerId]
    );

    // Calculate statistics
    const totalAssignments = projectAssignments.length;
    const activeAssignments = projectAssignments.filter(assignment => assignment.status === 'in_progress').length;
    const completedAssignments = projectAssignments.filter(assignment => assignment.status === 'complete').length;

    const totalUtilizedHours = projectAssignments.reduce((total, assignment) => 
      total + (assignment.utilizedHours || 0), 0);

    const totalEarnings = projectAssignments.reduce((total, assignment) => 
      total + (assignment.amountPaid || 0), 0);

    const averageHourlyRate = totalUtilizedHours > 0 
      ? totalEarnings / totalUtilizedHours 
      : 0;

    // Calculate average performance rating
    const performanceReviews = projectAssignments.flatMap(assignment => assignment.performanceReviews || []);
    const totalRating = performanceReviews.reduce((total, review) => total + review.rating, 0);
    const averagePerformanceRating = performanceReviews.length > 0 
      ? totalRating / performanceReviews.length 
      : 0;

    return {
      totalAssignments: {
        label: "Total Assignments",
        value: totalAssignments
      },
      activeAssignments: {
        label: "Active Assignments",
        value: activeAssignments
      },
      completedAssignments: {
        label: "Completed Assignments",
        value: completedAssignments
      },
      totalUtilizedHours: {
        label: "Total Utilized Hours",
        value: totalUtilizedHours
      },
      totalEarnings: {
        label: "Total Earnings",
        value: totalEarnings
      },
      averageHourlyRate: {
        label: "Average Hourly Rate",
        value: averageHourlyRate
      },
      averagePerformanceRating: {
        label: "Average Performance Rating",
        value: averagePerformanceRating
      }
    };
  } catch (error) {
    console.error("Error fetching freelancer statistics for client:", error);
    throw error;
  }
};


export const useFreelancerStatisticsForClient = (clientId: string, freelancerId: string) => {
  return useQuery<FreelancerStatistics, Error>({
    queryKey: ['freelancerStatistics', clientId, freelancerId],
    queryFn: () => getFreelancerStatisticsForClient(clientId, freelancerId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    networkMode: 'offlineFirst',
    retry: false,
    retryOnMount: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchIntervalInBackground: false,
    
  });
};
