import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

interface ClientRevenueChartProps {
  data: { [key: string]: { companyName: string; revenue?: { [year: string]: { [month: string]: number } }; expenses?: { [year: string]: { [month: string]: number } } } };
  selectedYear: string;
  isExpense?: boolean;
}

const ClientRevenueChart: React.FC<ClientRevenueChartProps> = ({ data, selectedYear, isExpense = false }) => {
  const theme = useTheme();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const colorPalette = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', 
    '#BB8FCE', '#F1948A', '#82E0AA', '#85C1E9', '#F8C471', '#D7BDE2', 
    '#FF9FF3', '#ABEBC6', '#AED6F1', '#F5B041'
  ];

  const chartData = Object.entries(data).map(([clientId, clientData], index) => ({
    label: clientData.companyName,
    data: monthNames.map((_, monthIndex) => {
      const yearData = isExpense ? clientData.expenses?.[selectedYear] : clientData.revenue?.[selectedYear];
      return yearData?.[monthIndex.toString()] || 0;
    }),
    color: colorPalette[index % colorPalette.length],
  }));

  return (
    <Box sx={{ display: 'flex', height: 400, width: '100%' }}>
      <Box sx={{ width: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center', pr: 2 }}>
        {chartData.map((series, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: series.color,
                mr: 1,
                borderRadius: 1,
              }}
            />
            <Typography variant="body2" noWrap>{series.label}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: '85%' }}>
        <LineChart
          xAxis={[{ 
            data: monthNames,
            scaleType: 'band',
          }]}
          series={chartData.map(series => ({
            data: series.data,
            label: series.label,
            color: series.color,
            valueFormatter: (value: number | null) => `$${value?.toFixed(2)}`,
          }))}
          width={1000}
          height={400}
          sx={{
            '.MuiLineElement-root': {
              strokeWidth: 2,
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ClientRevenueChart;