import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import { getAuth } from "firebase/auth";
import ViewFileDialog from "../../../components/ViewFileDialog";
import ProjectAssignmentsOverview from "./components/ProjectAssignmentsOverview";
import ProjectAssignmentCard from "./components/ProjectAssignmentCard";
import ProjectAssignmentsHeader from "./components/ProjectAssignmentsHeader";
import { getEngineerAssignments } from "core/projectAssignments";
import { getProjectName } from "core/projects";
import { getCSAName } from "core/csa";

const ProjectAssignments: React.FC = () => {
  const [assignments, setAssignments] = useState<ProjectAssignment[]>([]);
  const [filteredAssignments, setFilteredAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [clients, setClients] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>("all");
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAssignments = async () => {
      const auth = getAuth();
      let user = auth.currentUser;
      // const user = {
      //   uid: "fN5btREWaLafRckDKf7gInJBeTx1",
      //   email: "test@test.com",
      //   displayName: "Test User",
      //   photoURL: "https://example.com/jane-q-user/profile.jpg"
      // }
      if (!user) {
        console.warn("No authenticated user found.");
        setLoading(false);
        return;
      }

      try {
        const fetchedAssignments = await getEngineerAssignments(user.uid);
        const enrichedAssignments = await Promise.all(
          fetchedAssignments.map(async (assignment) => {
            const projectName = await getProjectName(
              assignment.clientId,
              assignment.projectId
            );
            const csaName = assignment.csaId
              ? await getCSAName(
                  assignment.clientId,
                  assignment.projectId,
                  assignment.csaId
                )
              : "";
            return {
              ...assignment,
              projectName,
              csaName,
            };
          })
        );

        setAssignments(enrichedAssignments);
        setFilteredAssignments(enrichedAssignments);

        const uniqueClients = Array.from(
          new Set(
            enrichedAssignments.map(
              (a) => a.projectName?.split(" - ")[0] || "Unknown Client"
            )
          )
        );
        setClients(uniqueClients);
      } catch (error) {
        console.error("Error fetching assignments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignments();
  }, []);

  useEffect(() => {
    filterAssignments();
  }, [selectedClient, selectedStatus, startDate, endDate, assignments]);

  const filterAssignments = () => {
    let filtered = assignments;

    if (selectedClient !== "all") {
      filtered = filtered.filter((a) =>
        a.projectName?.startsWith(selectedClient)
      );
    }

    if (selectedStatus !== "all") {
      filtered = filtered.filter((a) => a.status === selectedStatus);
    }

    if (startDate) {
      filtered = filtered.filter(
        (a) => a.startDate && a.startDate >= startDate
      );
    }

    if (endDate) {
      filtered = filtered.filter((a) => a.endDate && a.endDate <= endDate);
    }

    setFilteredAssignments(filtered);
  };

  const handleViewFile = (assignment: ProjectAssignment) => {
    if (assignment.fileUrl) {
      setSelectedFile({
        url: assignment.fileUrl,
        name: `${assignment.projectName} - Contract`,
      });
      setOpenFileDialog(true);
    }
  };

  const getStatusColor = (
    status: ProjectAssignment["status"]
  ):
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning" => {
    switch (status) {
      case "in_progress":
        return "success";
      case "complete":
        return "secondary";
      case "ready":
        return "warning";
      case "interviewing":
        return "info";
      default:
        return "default";
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        {/* <ProjectAssignmentsOverview assignments={assignments} /> */}

        <ProjectAssignmentsHeader
          clients={clients}
          onClientChange={setSelectedClient}
          onStatusChange={setSelectedStatus}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />

        <Grid container spacing={3}>
          {filteredAssignments.map((assignment) => (
            <Grid item xs={12} sm={6} md={4} key={assignment.id}>
              <ProjectAssignmentCard
                assignment={assignment}
                onViewFile={handleViewFile}
                getStatusColor={getStatusColor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedFile && (
        <ViewFileDialog
          open={openFileDialog}
          onClose={() => setOpenFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
    </>
  );
};

export default ProjectAssignments;
