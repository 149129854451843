import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Link,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
// import { VerificationSuccessDialog } from './components/VerificationSuccessDialog';
import { authTextFieldStyle } from "core/theme";

// TODO: Re-implemnt resend verification email @chaseleto
import { resendVerificationEmail } from "core/users";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const navigate = useNavigate();
  const {
    loginWithEmail,
    loginWithGoogle,
    setIsAuthenticated,
    // setUserInfo,
    isVerified,
    handleVerifyActionCode,
  } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    button: "",
    resendVerification: false,
    resetPassword: "",
    emailVerified: "",
  });

  const [currentEmail, setCurrentEmail] = useState("");

  const [searchParams] = useSearchParams();

  const token = searchParams.get("oobCode");
  const mode = searchParams.get("mode");

  const checkForEmailVerification = async () => {
    if (token) {
      setResetToken(token);
      if (mode === "verifyEmail") {
        // const response = await handleVerifyActionCode(token);
        const res = await handleVerifyActionCode(token);
        if (res) {
          setDialogContent({
            title: "Email Verified",
            body: `Your email has been verified. You can now login.`,
            button: "Ok",
            resendVerification: false,
            resetPassword: "",
            emailVerified: "",
          });
          setOpenDialog(true);
        }
      }
    }
  };

  useEffect(() => {
    checkForEmailVerification();
  }, [token]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };

    const response = await loginWithEmail(credentials);
    if (response && isVerified()) {
      
      // setIsAuthenticated(true);
    } else if (!isVerified()) {
      setCurrentEmail(credentials.email);
      setDialogContent({
        title: "Your email is not verified.",
        body: `Please verify your email to login.`,
        button: "Ok",
        resendVerification: true,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    } else if (!response) {
      setDialogContent({
        title: "Incorrect username or password.",
        body: `Please make sure you entered your username and password correctly.`,
        button: "Ok",
        resendVerification: false,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    }
  };

  const openLoginWithGooglePopup = async () => {
    const response = await loginWithGoogle();
    if (response && isVerified()) {
      setIsAuthenticated(true);
      navigate("/" + response.user_type);
    } else {
      setDialogContent({
        title: "Error logging in with Google. ",
        body: `Please contact admin@freetech.co to resolve this issue.`,
        button: "Ok",
        resendVerification: false,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate('/login');
  };

  const navigateToResetPassword = async () => {
    setOpenDialog(false);
    navigate("/reset-password?token=" + resetToken);
  };

  const handleResendVerificationEmail = async (email: string) => {
    const response = await resendVerificationEmail(email);

    

    handleCloseDialog();
  }

  const LoginDialog = () => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            {dialogContent.emailVerified && (
              <Button onClick={handleCloseDialog} color="primary">
                {dialogContent.button}
              </Button>
            )}
            {!dialogContent.resetPassword && (
              <Button onClick={handleCloseDialog} color="primary">
                {dialogContent.button}
              </Button>
            )}
            {dialogContent.resendVerification && (
              <Button onClick={() => handleResendVerificationEmail(currentEmail)} color="primary">
                Resend Verification Email
              </Button>
            )}
            {dialogContent.resetPassword && (
              <Button onClick={navigateToResetPassword} color="primary">
                {dialogContent.button}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const theme = useTheme();

  return (
    <>
      <LoginDialog />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%', mt: 4 }}>
        <TextField
          required
          fullWidth
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={{
            ...authTextFieldStyle,
            mb: 3,
            '& .MuiInputBase-input': {
              color: 'black',
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiFormHelperText-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={{
            ...authTextFieldStyle,
            mb: 4,
            '& .MuiInputBase-input': {
              color: 'black',
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiFormHelperText-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        />

        {/* <FormControlLabel
          control={<Checkbox value="remember" color="secondary" />}
          label="Remember me"
        /> */}

        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{
            mt: 2,
            mb: 3,
            bgcolor: 'black',
            color: 'white',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Sign In
        </Button>

        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: '#4285F4', // Google blue color
            color: 'white',
            '&:hover': {
              backgroundColor: '#357ae8', // Slightly darker shade for hover
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
            mb: 1,
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          Login with Google
        </Button>

        {/* Links */}
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2" sx={{ color: 'black' }}>
              {"Forgot password?"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
