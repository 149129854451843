import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';

export const uploadInvoicePDF = async (clientId: string, invoiceId: string, pdfBlob: Blob) => {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `clients/${clientId}/invoices/${invoiceId}.pdf`);
    const snapshot = await uploadBytes(storageRef, pdfBlob);
    
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading PDF:', error);
    throw error;
  }
};
