import React, { useState } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Paper,
  Stack,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import FreelancerCardGroup from "./components/FreelancerCardGroup";

const AdminProjectAssignments = () => {
  const [startDate, setStartDate] = useState<DateTime>(() => {
    return DateTime.local().startOf('month');
  });
  const [endDate, setEndDate] = useState<DateTime>(() => {
    return DateTime.local().endOf('month');
  });

  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedFreelancers, setSelectedFreelancers] = useState<string[]>([]);

  const [projects, setProjects] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [freelancerNames, setFreelancerNames] = useState<string[]>([]);

  const handleStartDateChange = (date: DateTime | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: DateTime | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Admin Project Assignments
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
            />
            <Autocomplete
              multiple
              options={projects}
              renderInput={(params) => (
                <TextField {...params} label="Projects" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              value={selectedProjects}
              onChange={(_, newValue) => setSelectedProjects(newValue)}
              sx={{ width: 300 }}
            />
            <Autocomplete
              multiple
              options={clients}
              renderInput={(params) => (
                <TextField {...params} label="Clients" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              value={selectedClients}
              onChange={(_, newValue) => setSelectedClients(newValue)}
              sx={{ width: 300 }}
            />
            <Autocomplete
              multiple
              options={freelancerNames}
              renderInput={(params) => (
                <TextField {...params} label="Freelancers" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              value={selectedFreelancers}
              onChange={(_, newValue) => setSelectedFreelancers(newValue)}
              sx={{ width: 300 }}
            />
          </Stack>
        </Paper>
        <FreelancerCardGroup
          startDate={startDate.toJSDate()}
          endDate={endDate.toJSDate()}
          selectedProjects={selectedProjects}
          selectedClients={selectedClients}
          selectedFreelancers={selectedFreelancers}
          setProjects={setProjects}
          setClients={setClients}
          setFreelancerNames={setFreelancerNames}
        />
      </Box>
    </>
  );
};

export default AdminProjectAssignments;
