import React, { useMemo, useEffect } from "react";
import { Grid, CircularProgress, Box, Typography } from "@mui/material";
import FreelancerCard from "./FreelancerCard";
import { useAdminFreelancers } from "hooks/admin";
import { clockifyClients } from "constants/clockifyToFirebase";

interface FreelancerCardGroupProps {
  startDate: Date | null;
  endDate: Date | null;
  selectedProjects: string[];
  selectedClients: string[];
  selectedFreelancers: string[];
  setProjects: React.Dispatch<React.SetStateAction<string[]>>;
  setClients: React.Dispatch<React.SetStateAction<string[]>>;
  setFreelancerNames: React.Dispatch<React.SetStateAction<string[]>>;
}

const FreelancerCardGroup: React.FC<FreelancerCardGroupProps> = ({
  startDate,
  endDate,
  selectedProjects,
  selectedClients,
  selectedFreelancers,
  setProjects,
  setClients,
  setFreelancerNames,
}) => {
  // const {
  //   data: freelancers,
  //   isLoading,
  //   isError,
  // } = useAdminFreelancers(startDate || undefined, endDate || undefined);

  // const { filteredFreelancers, projects, clients, freelancerNames } =
  //   useMemo(() => {
  //     const projects = Array.from(
  //       new Set(
  //         freelancers?.flatMap((f) =>
  //           f.projectAssignments
  //             ?.map((pa) => pa.projectName || "")
  //             .filter(Boolean)
  //         ) || []
  //       )
  //     );
  //     const clients = Array.from(
  //       new Set(
  //         freelancers?.flatMap((f) =>
  //           f.projectAssignments
  //             ?.map((pa) => pa.clientName || "")
  //             .filter(Boolean)
  //         ) || []
  //       )
  //     );
  //     const freelancerNames =
  //       freelancers?.map((f) => `${f.firstName} ${f.lastName}`) || [];

  //     const filteredFreelancers = freelancers
  //       ?.map((freelancer) => {
  //         const clientHours = clockifyClients.reduce(
  //           (acc, client) => {
  //             const clientTime =
  //               freelancer.clockifyData?.totals.totalTimeByClient[
  //                 client.clockifyId
  //               ] || 0;
  //             acc[client.firebaseId] = clientTime / 3600; // Convert seconds to hours
  //             return acc;
  //           },
  //           {} as Record<string, number>
  //         );

  //         return {
  //           ...freelancer,
  //           clientHours,
  //         };
  //       })
  //       .filter((freelancer) => {
  //         const fullName = `${freelancer.firstName} ${freelancer.lastName}`;
  //         return (
  //           (selectedFreelancers.length === 0 ||
  //             selectedFreelancers.includes(fullName)) &&
  //           (selectedProjects.length === 0 ||
  //             freelancer.projectAssignments.some((pa) =>
  //               selectedProjects.includes(pa.projectName || "")
  //             )) &&
  //           (selectedClients.length === 0 ||
  //             freelancer.projectAssignments.some((pa) =>
  //               selectedClients.includes(pa.clientName || "")
  //             ))
  //         );
  //       });

  //     return { filteredFreelancers, projects, clients, freelancerNames };
  //   }, [freelancers, selectedProjects, selectedClients, selectedFreelancers]);

  // useEffect(() => {
  //   setProjects(projects);
  //   setClients(clients);
  //   setFreelancerNames(freelancerNames);
  // }, [
  //   projects,
  //   clients,
  //   freelancerNames,
  //   setProjects,
  //   setClients,
  //   setFreelancerNames,
  // ]);

  // if (isLoading) {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       height="50vh"
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  // if (isError) {
  //   return <Typography color="error">Error loading freelancers</Typography>;
  // }

  // return (
  //   <Grid container spacing={3}>
  //     {filteredFreelancers?.map((freelancer) => (
  //       <Grid item xs={12} md={6} key={freelancer.id}>
  //         <FreelancerCard freelancer={freelancer} />
  //       </Grid>
  //     ))}
  //   </Grid>
  // );
  return <></>;
};

export default FreelancerCardGroup;
