import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const uploadProfilePicture = async (userId: string, file: File): Promise<string> => {
  const storage = getStorage();
//   const fileExtension = file.name.split('.').pop();
  const storageRef = ref(storage, `users/${userId}/profilePicture`);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};
