import { updateDocument } from "../firestore";

export const updateCSA = async (
  clientId: string,
  projectId: string,
  csaId: string,
  updates: Partial<ConsultingServicesAgreement>
): Promise<void> => {
  const csaPath = `clients/${clientId}/projects/${projectId}/consultingServicesAgreements/${csaId}`;
  await updateDocument(csaPath, updates);
};