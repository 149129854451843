/* eslint-disable */

// TODO: Remove eslint-disable and chanlde user info type in set state

import {
  getAuth,
  signInWithEmailAndPassword,
  // getAdditionalUserInfo,
  // sendEmailVerification
} from '@firebase/auth'
import { setJwtToken } from 'core'
import React from 'react'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

export const handleLoginWithEmail = async (
  email: string,
  password: string,
  setUserInfo: React.Dispatch<any>,
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const auth = getAuth()
  const db = getFirestore() // Initialize Firestore

  try {
    const userCredentialObj = await signInWithEmailAndPassword(auth, email, password)
    const jwt = await userCredentialObj.user.getIdToken()
    setJwtToken(jwt) // Assuming this function exists to store JWT locally

    const user = userCredentialObj.user
    const userDocRef = doc(db, 'users', user.uid) // Reference to the user's Firestore document
    const userDocSnap = await getDoc(userDocRef)

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data()
      setUserInfo(userData) // Set user info with data retrieved from Firestore
      setIsAuthenticated(true)
    } else {
      console.log('No user data available in Firestore')
      setIsAuthenticated(false) // Handle case where no data is available
    }

    return userCredentialObj.user
  } catch (error) {
    console.error('Error logging in:', error)
    setIsAuthenticated(false)
    return false
  }
}
