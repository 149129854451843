import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  collectionGroup,
  query,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  collection,
  addDoc,
  updateDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "config/firebase";
import { getClientCompanyName } from "../../core/clients/getClientCompanyName";
import { getProjectName } from "../../core/projects";

export interface AdminFreelancer extends Freelancer {
  projectAssignments: ProjectAssignment[];
  documents: EngineerDocument[];
}

// Core fetch functions
const fetchAllUsers = async (): Promise<Freelancer[]> => {
  const usersQuery = query(
    collection(db, "users"),
    where("user_type", "in", ["freelancer", "admin"])
  );
  const usersSnapshot = await getDocs(usersQuery);
  return usersSnapshot.docs.map(doc => ({
    ...doc.data() as Freelancer,
    id: doc.id,
  }));
};

const fetchProjectAssignments = async (): Promise<Record<string, ProjectAssignment[]>> => {
  const projectAssignmentsQuery = query(collectionGroup(db, "projectAssignments"));
  const querySnapshot = await getDocs(projectAssignmentsQuery);
  const allProjectAssignments = await Promise.all(
    querySnapshot.docs
      .map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }) as ProjectAssignment)
      .filter(
        (assignment) =>
          assignment.status === "in_progress" ||
          assignment.everSignDocumentStatus === "awaiting_engineer_signature"
      )
      .map(async (assignment) => {
        const clientName = await getClientCompanyName(assignment.clientId);
        const projectName = await getProjectName(
          assignment.clientId,
          assignment.projectId
        );
        return { 
          ...assignment, 
          clientName,
          projectName 
        };
      })
  );

  return allProjectAssignments.reduce((acc, assignment) => {
    if (!acc[assignment.engineerId]) {
      acc[assignment.engineerId] = [];
    }
    acc[assignment.engineerId].push(assignment);
    return acc;
  }, {} as Record<string, ProjectAssignment[]>);
};

const fetchUserDocuments = async (userId: string): Promise<EngineerDocument[]> => {
  try {
    const documentsQuery = query(collection(db, "users", userId, "documents"));
    const documentsSnapshot = await getDocs(documentsQuery);
    return documentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as EngineerDocument));
  } catch (error) {
    console.log(`No documents found for user ${userId}`);
    return [];
  }
};

const fetchAllEngineersWithProjectAssignments = async (): Promise<AdminFreelancer[]> => {
  // Fetch all base user data
  const users = await fetchAllUsers();
  
  // Fetch all project assignments in parallel
  const assignmentsByEngineerId = await fetchProjectAssignments();
  
  // Fetch documents for each user in parallel
  const adminFreelancers = await Promise.all(
    users.map(async (user) => {
      const documents = await fetchUserDocuments(user.id);
      
      return {
        ...user,
        projectAssignments: assignmentsByEngineerId[user.id] || [],
        documents: documents,
      } as AdminFreelancer;
    })
  );

  return adminFreelancers;
};

export const useAdminFreelancers = () => {
  const queryClient = useQueryClient();

  // Query for fetching freelancers
  const query: UseQueryResult<AdminFreelancer[], Error> = useQuery({
    queryKey: ["adminFreelancers"],
    queryFn: fetchAllEngineersWithProjectAssignments,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  // Mutation for deleting project assignments
  const deleteProjectAssignment: UseMutationResult<
    boolean,
    Error,
    { clientId: string; engineerId: string; assignmentId: string }
  > = useMutation({
    mutationFn: async ({ clientId, engineerId, assignmentId }) => {
      await deleteDoc(doc(db, "clients", clientId, "projectAssignments", assignmentId));
      await deleteDoc(doc(db, "users", engineerId, "projectAssignments", assignmentId));
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  // Mutation for uploading documents
  const uploadDocument: UseMutationResult<
    string,
    Error,
    { freelancerId: string; file: File; documentData: Partial<EngineerDocument> }
  > = useMutation({
    mutationFn: async ({ freelancerId, file, documentData }) => {
      const storage = getStorage();
      const fileRef = ref(storage, `users/${freelancerId}/documents/${file.name}`);
      
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const docData = {
        ...documentData,
        uploadDate: Timestamp.now().toDate().toISOString(),
        fileUrl,
      };

      const docRef = await addDoc(
        collection(db, "users", freelancerId, "documents"),
        docData
      );

      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  // Mutation for updating documents
  const updateDocument: UseMutationResult<
    void,
    Error,
    { freelancerId: string; documentId: string; updates: Partial<EngineerDocument> }
  > = useMutation({
    mutationFn: async ({ freelancerId, documentId, updates }) => {
      const docRef = doc(db, "users", freelancerId, "documents", documentId);
      await updateDoc(docRef, updates);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  // Mutation for deleting documents
  const deleteDocument: UseMutationResult<
    void,
    Error,
    { freelancerId: string; documentId: string }
  > = useMutation({
    mutationFn: async ({ freelancerId, documentId }) => {
      await deleteDoc(doc(db, "users", freelancerId, "documents", documentId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminFreelancers"] });
    },
  });

  return {
    // Query results
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    
    // Project Assignment Mutations
    deleteProjectAssignment: deleteProjectAssignment.mutate,
    isDeletingAssignment: deleteProjectAssignment.isPending,
    deleteAssignmentError: deleteProjectAssignment.error,

    // Document Mutations
    uploadDocument: uploadDocument.mutate,
    isUploadingDocument: uploadDocument.isPending,
    uploadDocumentError: uploadDocument.error,

    updateDocument: updateDocument.mutate,
    isUpdatingDocument: updateDocument.isPending,
    updateDocumentError: updateDocument.error,

    deleteDocument: deleteDocument.mutate,
    isDeletingDocument: deleteDocument.isPending,
    deleteDocumentError: deleteDocument.error,
  };
};
