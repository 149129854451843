import { db } from "config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { templates, TemplateType, TemplateParams } from "./mailTemplates";

export const sendMailTemplate = async <T extends TemplateType>(
  to: string,
  subject: string,
  templateName: T,
  templateParams: TemplateParams[T]
): Promise<boolean> => {
  const mailRef = collection(db, "mail");
  const template = templates[templateName] as (params: TemplateParams[T]) => string;
  const body = template(templateParams);

  try {
    await addDoc(mailRef, {
      to: to,
      message: {
        subject: subject,
        html: body,
      },
    });
    return true;
  } catch (error) {
    console.error("Error sending email:", error);
    return false;
  }
};
