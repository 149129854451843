import { getStorage, ref, uploadBytes, getDownloadURL, getMetadata } from "firebase/storage";

export const storage = getStorage();

export const uploadFile = async (path: string, file: File) => {
  const fileRef = ref(storage, path);
  await uploadBytes(fileRef, file);
  return await getDownloadURL(fileRef);
};

export const getFileUrl = async (path: string) => {
  const fileRef = ref(storage, path);
  return await getDownloadURL(fileRef);
};

export const fileExists = async (path: string) => {
  const fileRef = ref(storage, path);
  try {
    await getMetadata(fileRef);
    return true;
  } catch {
    return false;
  }
};