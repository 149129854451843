import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

interface RevenueByClientPieChartProps {
  data: { [key: string]: { companyName: string; revenue: { [year: string]: { [month: string]: number } } } };
  selectedYear: string;
}

const RevenueByClientPieChart: React.FC<RevenueByClientPieChartProps> = ({ data, selectedYear }) => {
  const theme = useTheme();

  const colorPalette = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', 
    '#BB8FCE', '#F1948A', '#82E0AA', '#85C1E9', '#F8C471', '#D7BDE2', 
    '#FF9FF3', '#ABEBC6', '#AED6F1', '#F5B041'
  ];

  const chartData = Object.entries(data)
    .map(([clientId, clientData], index) => {
      const yearRevenue = Object.values(clientData.revenue[selectedYear] || {}).reduce((sum, value) => sum + value, 0);
      return {
        id: clientId,
        value: yearRevenue,
        label: clientData.companyName,
        color: colorPalette[index % colorPalette.length],
      };
    })
    .filter(item => item.value > 0) // Filter out items with zero revenue
    .sort((a, b) => b.value - a.value); // Sort by value in descending order

  return (
    <>
      <Box sx={{ height: '100%', width: '110%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {chartData.length > 0 ? (
          <PieChart
            series={[
              {
                data: chartData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                arcLabel: (params) => (params.label ?? '').split(' ').map(word => word.slice(0, 1)).join(''),
              },
            ]}
            height={350}
            width={600}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        ) : (
          <Typography variant="body1">No revenue data available for the selected year.</Typography>
        )}
      </Box>
    </>
  );
};

export default RevenueByClientPieChart;