import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Chip,
  IconButton,
  Grid,
  Tooltip,
  Snackbar,
  Alert,
  useTheme,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAdminProjectAssignmentCard } from "hooks/admin/useAdminProjectAssignmentCard";
import ViewFileDialog from "components/ViewFileDialog";
import PerformanceReviewDialog from "./PerformanceReviewDialog";
import { addPerformanceReview } from "core/projectAssignments/addPerformanceReview";
import { useAuth } from "contexts/AuthContext";
import AssignmentEditForm from "./AssignmentEditForm";

interface AssignmentCardProps {
  assignmentId: string;
  onDelete: (assignment: ProjectAssignment) => void;
  client: Client;
}

export const AssignmentCard: React.FC<AssignmentCardProps> = React.memo(
  ({ assignmentId, onDelete, client }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localAssignment, setLocalAssignment] = useState<ProjectAssignment | null>(null);
    const [selectedFile, setSelectedFile] = useState<{ url: string; name: string } | null>(null);
    const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
    const [openPerformanceReviewDialog, setOpenPerformanceReviewDialog] = useState(false);
    const { userInfo } = useAuth();
    const theme = useTheme();

    console.log("assignmentId", assignmentId);

    if (!assignmentId) {
      return null;
    }

    const { assignment, isLoading, error, updateAssignment } = useAdminProjectAssignmentCard(client.id, assignmentId);

    const [snackbar, setSnackbar] = useState<{
      open: boolean;
      message: string;
      severity: "error" | "success";
    }>({
      open: false,
      message: "",
      severity: "success",
    });

    useEffect(() => {
      if (assignment) {
        setLocalAssignment(assignment);
      }
    }, [assignment]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!assignment || !localAssignment) return null;

    const getPAStatusColor = (status: ProjectAssignmentStatus) => {
      switch (status) {
        case "interviewing": return "#3498db";
        case "ready": return "#2ecc71";
        case "signing": return "#95a5a6";
        case "in_progress": return "#f39c12";
        case "complete": return "#95a5a6";
      }
    };

    const handleViewAssignment = () => {
      if (localAssignment.fileUrl) {
        setSelectedFile({
          url: localAssignment.fileUrl,
          name: `Assignment for ${localAssignment.projectName || 'Unnamed Project'}`,
        });
        setOpenViewFileDialog(true);
      }
    };

    const handleEdit = () => {
      setIsEditing(true);
    };

    const handleSave = async (updatedAssignment: ProjectAssignment) => {
      try {
        
        await updateAssignment(updatedAssignment);
        setLocalAssignment(updatedAssignment);
        setIsEditing(false);
        setSnackbar({
          open: true,
          message: "Assignment updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating assignment:", error);
        setSnackbar({
          open: true,
          message: "Error updating assignment",
          severity: "error",
        });
      }
    };

    const handleCancel = () => {
      setIsEditing(false);
    };

    const handleOpenPerformanceReview = () => {
      setOpenPerformanceReviewDialog(true);
    };

    const handleAddPerformanceReview = async (reviewData: PerformanceReview) => {
      if (localAssignment) {
        try {
          await addPerformanceReview(client.id, localAssignment.id, reviewData);
          setSnackbar({
            open: true,
            message: "Performance review added successfully",
            severity: "success",
          });
          setOpenPerformanceReviewDialog(false);
        } catch (error) {
          console.error("Error adding performance review:", error);
          setSnackbar({
            open: true,
            message: "Error adding performance review",
            severity: "error",
          });
        }
      }
    };

    return (
      <>
        {selectedFile && (
          <ViewFileDialog
            open={openViewFileDialog}
            onClose={() => setOpenViewFileDialog(false)}
            fileUrl={selectedFile.url}
            fileName={selectedFile.name}
          />
        )}

        <PerformanceReviewDialog
          open={openPerformanceReviewDialog}
          onClose={() => setOpenPerformanceReviewDialog(false)}
          onSubmit={handleAddPerformanceReview}
          assignment={localAssignment || assignment}
          engineerName={localAssignment?.engineerId || 'Unknown'}
          currentUser={{
            id: userInfo?.id || "",
            email: userInfo?.email || "Unknown User",
          }}
        />

        <Grid item xs={12} sm={isEditing ? 12 : 6} md={isEditing ? 12 : 4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: theme.shadows[6],
              },
              ...(isEditing && {
                border: "2px solid",
                borderColor: "primary.main",
                boxShadow: theme.shadows[10],
              }),
            }}
          >
            <CardContent sx={{ flexGrow: 1, p: 2, position: "relative" }}>
              <Chip
                label={localAssignment.status}
                sx={{
                  position: "absolute",
                  top: theme.spacing(1),
                  right: theme.spacing(1),
                  backgroundColor: getPAStatusColor(localAssignment.status),
                  color: "white",
                }}
              />
              <Typography variant="subtitle1" gutterBottom>
                {localAssignment.projectName || 'No Project Assigned'}
              </Typography>
              {isEditing ? (
                <AssignmentEditForm
                  assignment={localAssignment}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  onDelete={onDelete}
                  handleViewFile={handleViewAssignment}
                  client={client}
                />
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                      CSA: {localAssignment.csaName || 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                      ID# {localAssignment.id || 'N/A'}
                    </Typography>

                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      Start Date: {localAssignment.startDate || 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                      End Date: {localAssignment.endDate || 'N/A'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2">
                      Rate: <span style={{ fontWeight: 'bold' }}>${localAssignment.billableRate}/hr</span>
                    </Typography>
                    <Typography variant="body2">
                      Hours: <span style={{ fontWeight: 'bold' }}>{localAssignment.budgetedHours || 'N/A'}</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body2">
                      Utilized: <span style={{ fontWeight: 'bold' }}>{localAssignment.utilizedHours || 'N/A'}</span>
                    </Typography>
                    <Typography variant="body2">
                      Paid: <span style={{ fontWeight: 'bold' }}>${localAssignment.amountPaid || 'N/A'}</span>
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
              {!isEditing && (
                <>
                  <IconButton onClick={handleOpenPerformanceReview} size="small">
                    <RateReviewIcon />
                  </IconButton>
                  {localAssignment.fileUrl && (
                    <IconButton onClick={handleViewAssignment} size="small">
                      <VisibilityIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleEdit} size="small">
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Box>
          </Card>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
);
