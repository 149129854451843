import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Divider, TextField, Select, MenuItem, SelectChangeEvent, Accordion, AccordionSummary, AccordionDetails, InputAdornment } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import UploadCSADialog from "./UploadCSADialog";
import { updateProjectStatus, updateProjectDates } from "core/projects";
// import { getProjectCSAs, getCSAFile, updateCSAStatus } from "../api/csa";
import { getProjectCSAs } from "core/projects/getProjectCsaDocs";
import { readCSA } from "core/csa/readCSA";
import { updateCSA } from "core/csa/updateCSA";
import { getCSAFileFromStorage } from "core/csa/getCSAFileFromStorage";

import ViewFileDialog from "../../../../components/ViewFileDialog";

interface ProjectCardProps {
  project: Project;
  clientId: string;
  onUpdate: (updatedProject: Project) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, clientId, onUpdate }) => {
  const [openCSADialog, setOpenCSADialog] = useState(false);
  const [csas, setCSAs] = useState<ConsultingServicesAgreement[]>([]);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editedProject, setEditedProject] = useState<Project>(project);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{ url: string; name: string } | null>(null);
  const [csaExpanded, setCsaExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCSAs, setFilteredCSAs] = useState<ConsultingServicesAgreement[]>([]);


  

  useEffect(() => {
    loadCSAs();
  }, [project.id, clientId]);

  useEffect(() => {
    const filtered = csas.filter(csa => 
      Object.values(csa).some(value => 
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredCSAs(filtered);
  }, [searchTerm, csas]);

  const loadCSAs = async () => {
    try {
      const projectCSAs = await getProjectCSAs(clientId, project.id);
      setCSAs(projectCSAs);
    } catch (error) {
      console.error("Error loading CSAs:", error);
    }
  };

  const formatDate = (date: string | null) => {
    return date ? new Date(date).toISOString().split('T')[0] : "";
  };

  const handleViewAgreement = async (csa: ConsultingServicesAgreement) => {
    try {
      const blob = await getCSAFileFromStorage(clientId, project.id, csa.id);
      if (blob) {
        setSelectedFile({ url: blob, name: csa.name });
        setOpenViewFileDialog(true);
      } else {
        console.error("CSA file not found");
        // You can add an error message here
      }
    } catch (error) {
      console.error("Error fetching CSA file:", error);
      // You can add an error message here
    }
  };

  const getStatusColor = (status: ProjectStatus | CSAStatus) => {
    switch (status) {
      case 'idea': return '#3498db'; // Blue
      case 'active': return '#2ecc71'; // Green
      case 'completed': return '#9b59b6'; // Purple
      case 'on_hold': return '#f39c12'; // Orange
      case 'cancelled': return '#e74c3c'; // Red
      default: return '#95a5a6'; // Grey
    }
  };

  const handleProjectStatusChange = async (event: SelectChangeEvent<ProjectStatus>) => {
    const newStatus = event.target.value as ProjectStatus;
    try {
      await updateProjectStatus(clientId, project.id, newStatus);
      const updatedProject = { ...project, status: newStatus };
      onUpdate(updatedProject);
      setEditedProject(updatedProject);
    } catch (error) {
      console.error("Error updating project status:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCSAStatusChange = async (csaId: string, event: SelectChangeEvent<CSAStatus>) => {
    const newStatus = event.target.value as CSAStatus;
    try {
      await updateCSA(clientId, project.id, csaId, { status: newStatus });
      const updatedCSAs = csas.map(csa => 
        csa.id === csaId ? { ...csa, status: newStatus } : csa
      );
      setCSAs(updatedCSAs);
    } catch (error) {
      console.error("Error updating CSA status:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleEdit = (field: string) => {
    setEditingField(field);
  };

  const handleSave = async () => {
    if (editingField === 'status') {
      await updateProjectStatus(clientId, project.id, editedProject.status);
    } else {
      await updateProjectDates(clientId, project.id, {
        ideaDate: editedProject.ideaDate,
        startDate: editedProject.startDate,
        endDate: editedProject.endDate,
      });
    }
    onUpdate(editedProject);
    setEditingField(null);
  };

  const handleChange = (field: string, value: string | null) => {
    setEditedProject({ ...editedProject, [field]: value });
  };

  return (
    <Card elevation={3} sx={{ mb: 3, width: '100%' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" component="div">
            {project.name}
          </Typography>
          <Select
            value={editedProject.status}
            onChange={handleProjectStatusChange}
            sx={{
              height: 32,
              '& .MuiSelect-select': {
                paddingTop: 0,
                paddingBottom: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',  // Remove the border
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',  // Remove the border on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',  // Remove the border when focused
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 200,
                },
              },
            }}
            renderValue={(selected) => (
              <Chip
                label={selected}
                size="small"
                sx={{
                  backgroundColor: getStatusColor(selected),
                  color: 'white',
                  fontWeight: 'bold',
                  '& .MuiChip-label': { px: 2 },
                }}
              />
            )}
          >
            {['idea', 'active', 'completed', 'on_hold', 'cancelled'].map((status) => (
              <MenuItem key={status} value={status}>
                <Chip
                  label={status}
                  size="small"
                  sx={{
                    backgroundColor: getStatusColor(status as ProjectStatus),
                    color: 'white',
                    fontWeight: 'bold',
                    '& .MuiChip-label': { px: 2 },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="body2" color="text.secondary" paragraph>
          {project.description}
        </Typography>
        <Box display="flex" justifyContent="space-between" mb={2}>
          {['ideaDate', 'startDate', 'endDate'].map((field) => (
            <Box key={field}>
              <Typography variant="body2">
                <strong>{field.charAt(0).toUpperCase() + field.slice(1)}:</strong>
              </Typography>
              {editingField === field ? (
                <TextField
                  type="date"
                  value={formatDate(editedProject[field as keyof Project] as string | null)}
                  onChange={(e) => handleChange(field, e.target.value)}
                  onBlur={handleSave}
                  autoFocus
                />
              ) : (
                <Typography variant="body2" onClick={() => handleEdit(field)} sx={{ cursor: 'pointer' }}>
                  {formatDate(project[field as keyof Project] as string | null) || "Not set"}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Divider sx={{ my: 2 }} />
        
        <Accordion 
          expanded={csaExpanded} 
          onChange={() => setCsaExpanded(!csaExpanded)}
          sx={{ 
            '&:before': { display: 'none' }, 
            boxShadow: 'none',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-csa-content"
            id="panel-csa-header"
            sx={{ 
              backgroundColor: 'background.paper',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Typography variant="h6" flexGrow={1}>Consulting Services Agreements</Typography>
              <Typography variant="body2" color="text.secondary" mr={1}>
                {csas.length} {csas.length === 1 ? 'Agreement' : 'Agreements'}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <CardContent sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Button 
                  size="small" 
                  color="primary" 
                  variant="contained"
                  onClick={() => setOpenCSADialog(true)} 
                  sx={{ 
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                  }}
                >
                  Upload CSA
                </Button>
                <TextField
                  placeholder="Search CSAs"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {filteredCSAs.length > 0 ? (
                <TableContainer 
                  component={Paper} 
                  variant="outlined" 
                  sx={{ 
                    border: 'none', 
                    boxShadow: 'none',
                    '& .MuiTableCell-root': {
                      borderColor: 'divider',
                    },
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Name</strong></TableCell>
                        <TableCell><strong>Effective Date</strong></TableCell>
                        <TableCell><strong>Budget</strong></TableCell>
                        <TableCell><strong>Billable Rates</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                        <TableCell><strong>Action</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCSAs.map((csa) => (
                        <TableRow key={csa.id}>
                          <TableCell>{csa.name}</TableCell>
                          <TableCell>{new Date(csa.effectiveDate).toLocaleDateString()}</TableCell>
                          <TableCell>
                            {csa.fixedPriceBudget ? 
                              `$${csa.fixedPriceBudget.toLocaleString()}/fixed` : 
                              csa.monthlyBudget ? 
                                `$${csa.monthlyBudget.toLocaleString()}/month` : 
                                'N/A'
                            }
                          </TableCell>
                          <TableCell>
                            {csa.billableRates && csa.billableRates.length > 0 ? (
                              csa.billableRates.map((rate, index) => (
                                <div key={index}>
                                  {rate.role}: ${rate.rate.toLocaleString()}/hr
                                </div>
                              ))
                            ) : (
                              <div>No billable rates available</div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Select
                              value={csa.status}
                              onChange={(event) => handleCSAStatusChange(csa.id, event as SelectChangeEvent<CSAStatus>)}
                              sx={{
                                height: 32,
                                '& .MuiSelect-select': {
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                              }}
                            >
                              {['active', 'completed', 'cancelled'].map((status) => (
                                <MenuItem key={status} value={status}>
                                  <Chip
                                    label={status}
                                    size="small"
                                    sx={{
                                      backgroundColor: getStatusColor(status as CSAStatus),
                                      color: 'white',
                                      fontWeight: 'bold',
                                      '& .MuiChip-label': { px: 2 },
                                    }}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleViewAgreement(csa)}
                            >
                              View Agreement
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No matching CSAs found.
                </Typography>
              )}
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </CardContent>
      <UploadCSADialog
        open={openCSADialog}
        onClose={() => {
          setOpenCSADialog(false);
          loadCSAs();
        }}
        clientId={clientId}
        projectId={project.id}
      />
      {selectedFile && (
        <ViewFileDialog
          open={openViewFileDialog}
          onClose={() => {
            setOpenViewFileDialog(false);
            URL.revokeObjectURL(selectedFile.url);
            setSelectedFile(null);
          }}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
    </Card>
  );
};

export default ProjectCard;