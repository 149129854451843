import { functionsBaseUrl } from "constants/functionsBaseUrl";
import {
  RetrieveAccountBalanceInput,
  RetrieveAccountBalanceOutput,
  ListAccountTransactionsInput,
  ListAccountTransactionsOutput,
  RetrieveAccountDetailsInput,
  RetrieveAccountDetailsOutput,
} from "./types/increase/accounts";
import {
  HandleAchTransferInput,
  HandleAchTransferOutput,
} from "./types/increase/ach";
import {
  SimulateInboundAchTransferInput,
  SimulateInboundAchTransferOutput,
} from "./types/increase/simulations";
import {
  CreateExternalAccountInput,
  CreateExternalAccountOutput,
  RetrieveExternalAccountInput,
  RetrieveExternalAccountOutput,
  ListExternalAccountsInput,
  ListExternalAccountsOutput,
  UpdateExternalAccountInput,
  UpdateExternalAccountOutput,
} from "./types/increase/externalAccounts";
import Increase from 'increase';
  

const increaseFunctionUrlsConfig = {
  accounts: {
    retrieveAccountBalance: {
      url: `${functionsBaseUrl}/increase/account/balance`,
      method: "POST",
      input: {} as RetrieveAccountBalanceInput,
      output: {} as RetrieveAccountBalanceOutput
    },
    listAccountTransactions: {
      url: `${functionsBaseUrl}/increase/account/transactions`,
      method: "POST",
      input: {} as ListAccountTransactionsInput,
      output: {} as ListAccountTransactionsOutput,
    },
    retrieveAccountDetails: {
      url: `${functionsBaseUrl}/increase/account/retrieve`,
      method: "POST",
      input: {} as RetrieveAccountDetailsInput,
      output: {} as RetrieveAccountDetailsOutput,
    },
  },

  externalAccounts: {
    createExternalAccount: {
      url: `${functionsBaseUrl}/increase/external-accounts/create`,
      method: "POST",
      input: {} as CreateExternalAccountInput,
      output: {} as CreateExternalAccountOutput,
    },
    retrieveExternalAccount: {
      url: `${functionsBaseUrl}/increase/external-accounts/retrieve`,
      method: "GET",
      input: {} as RetrieveExternalAccountInput,
      output: {} as RetrieveExternalAccountOutput,
    },
    updateExternalAccount: {
      url: `${functionsBaseUrl}/increase/external-accounts/update`,
      method: "PUT",
      input: {} as UpdateExternalAccountInput,
      output: {} as UpdateExternalAccountOutput,
    },
    listExternalAccounts: {
      url: `${functionsBaseUrl}/increase/external-accounts/list`,
      method: "POST",
      input: {} as ListExternalAccountsInput,
      output: {} as ListExternalAccountsOutput,
    },
  },
  ach: {
    createInboundAchTransfer: {
      url: `${functionsBaseUrl}/increase/ach/create-inbound-ach-transfer`,
      method: "POST",
      input: {} as HandleAchTransferInput,
      output: {} as HandleAchTransferOutput,
    },
    createOutboundAchTransfer: {
      url: `${functionsBaseUrl}/increase/ach/create-inbound-ach-transfer`,
      method: "POST",
      input: {} as HandleAchTransferInput,
      output: {} as HandleAchTransferOutput,
    },
  },

  simulations: {
    simulateInboundAchTransfer: {
      url: `${functionsBaseUrl}/increase/simulation/inbound-ach-transfer`,
      method: "POST",
      input: {} as SimulateInboundAchTransferInput,
      output: {} as SimulateInboundAchTransferOutput,
    },
  },
} as const;

export const increaseFunctionUrls: NestedFunctionUrls<typeof increaseFunctionUrlsConfig> = increaseFunctionUrlsConfig;
