import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import ProtectedRoute from "./ProtectedRoute";

const FreelancerRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { userInfo } = useAuth();

  return (
    <ProtectedRoute>
      {userInfo?.user_type === "freelancer" ||
      userInfo?.user_type === "admin" ? (
        children
      ) : (
        <Navigate to="/not-found" replace />
      )}
    </ProtectedRoute>
  );
};

export default FreelancerRoute;
