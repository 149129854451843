import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from 'config/firebase';

export const createInvoice = async (clientId: string, invoiceData: InvoiceData) => {
  const invoiceRef = doc(collection(db, `clients/${clientId}/invoices`));
  await setDoc(invoiceRef, {
    ...invoiceData,
    id: invoiceRef.id,
    createdAt: new Date(),
  });
  return invoiceRef.id;
};
