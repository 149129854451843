import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Typography,
  Box,
  Divider,
  useTheme,
  CircularProgress,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { collection, query, getDocs, getFirestore, doc, where } from "firebase/firestore"
import { getUserProfile } from "../../../../../core/users/getUserProfile"
import { findProjects } from "core/clockify/projects"

interface AddEngineerDialogProps {
  open: boolean
  onClose: () => void
  onAdd: (engineerData: UserInfoType) => Promise<void>
  client: Client
  currentEngineers: Freelancer[] | undefined
}

export const AddEngineerDialog: React.FC<AddEngineerDialogProps> = ({
  open,
  onClose,
  onAdd,
  client,
  currentEngineers,
}) => {
  const [engineers, setEngineers] = useState<UserInfoType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const db = getFirestore()
  const theme = useTheme()

  useEffect(() => {
    const fetchEngineers = async () => {
      const q = query(collection(db, "users"), where("user_type", "in", ["admin", "freelancer"]));
      const querySnapshot = await getDocs(q);
      
      const engineersList = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const userProfile = await getUserProfile(doc.id);
          return userProfile;
        })
      );
      
      // Filter out engineers that are already associated with the client
      const availableEngineers = engineersList.filter(
        (user): user is UserInfoType => 
          user !== null && !currentEngineers?.some(currentEngineer => currentEngineer.id === user.id)
      );

      setEngineers(availableEngineers);
    };

    if (open) {
      fetchEngineers();
    }
  }, [open, currentEngineers]);

  const handleAdd = (engineer: UserInfoType) => {
    const engineerData = Object.entries(engineer).reduce((acc, [key, value]) => {
      acc[key] = value === undefined ? '' : value;
      return acc;
    }, {} as Record<string, any>);

    onAdd({ 
      ...engineerData,
      id: engineer.id,
      email: engineerData.email,
      status: "ready",
      associationDate: new Date().toISOString(),
      projects: [],
      user_type: 'freelancer'
    } as Freelancer);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Engineer</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {engineers.map((engineer) => (
              <React.Fragment key={engineer.id}>
                <ListItem>
                  <Box display="flex" alignItems="center" width="100%">
                    <Avatar
                      src={engineer.profilePicture}
                      alt={`${engineer.firstName} ${engineer.lastName}`}
                      sx={{ width: 60, height: 60, marginRight: 2 }}
                    />
                    <Box flexGrow={1}>
                      <Typography variant="h6">
                        {engineer.firstName} {engineer.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {engineer.email}
                      </Typography>
                      {engineer.skills && (
                        <Typography variant="body2" color="textSecondary">
                          Skills: {engineer.skills.join(", ")}
                        </Typography>
                      )}
                    </Box>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={() => handleAdd(engineer)}
                        sx={{
                          color: theme.palette.primary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                          },
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
            {engineers.length === 0 && (
              <Typography variant="body1" align="center">
                No engineers available to add.
              </Typography>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
