import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { uploadInvoicePDF } from 'core/invoices';

export const createAndSavePDF = async (invoiceData: InvoiceData, clientId: string, invoiceId: string) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    // Company details
    page.drawText('FreeTech', { x: 50, y: 750, size: 24, font: boldFont });
    page.drawText('104 Fox Court', { x: 50, y: 730, size: 10, font });
    page.drawText('Winchester, VA 22603', { x: 50, y: 715, size: 10, font });
    page.drawText('admin@freetech.co', { x: 50, y: 700, size: 10, font });
    page.drawText('+1 (571)-291-1193', { x: 50, y: 685, size: 10, font });
    page.drawText('freetech.co', { x: 50, y: 670, size: 10, font });

    // Invoice title
    page.drawText('INVOICE', { x: 450, y: 750, size: 24, font: boldFont });

    // Bill To
    page.drawText('Bill to', { x: 50, y: 600, size: 12, font: boldFont });
    page.drawText(invoiceData.billTo.name, { x: 50, y: 585, size: 10, font });
    page.drawText(invoiceData.billTo.company, { x: 50, y: 570, size: 10, font });

    // Ship To
    page.drawText('Ship to', { x: 300, y: 600, size: 12, font: boldFont });
    page.drawText(invoiceData.shipTo.name, { x: 300, y: 585, size: 10, font });
    page.drawText(invoiceData.shipTo.company, { x: 300, y: 570, size: 10, font });

    // Invoice details
    page.drawText('Invoice details', { x: 50, y: 520, size: 12, font: boldFont });
    page.drawText(`Invoice no.: ${invoiceData.invoiceNumber}`, { x: 50, y: 505, size: 10, font });
    page.drawText(`Terms: ${invoiceData.terms}`, { x: 50, y: 490, size: 10, font });
    page.drawText(`Invoice date: ${invoiceData.invoiceDate}`, { x: 50, y: 475, size: 10, font });
    page.drawText(`Due date: ${invoiceData.dueDate}`, { x: 50, y: 460, size: 10, font });

    // Table header
    page.drawText('#', { x: 50, y: 420, size: 10, font: boldFont });
    page.drawText('Product or Service', { x: 80, y: 420, size: 10, font: boldFont });
    page.drawText('Qty', { x: 300, y: 420, size: 10, font: boldFont });
    page.drawText('Rate', { x: 350, y: 420, size: 10, font: boldFont });
    page.drawText('Amount', { x: 500, y: 420, size: 10, font: boldFont });

    // Table content
    let yOffset = 400;
    let subtotal = 0;
    invoiceData.items.forEach((item, index) => {
      const amount = item.quantity * item.rate;
      subtotal += amount;
      page.drawText((index + 1).toString(), { x: 50, y: yOffset, size: 10, font });
      page.drawText(item.description, { x: 80, y: yOffset, size: 10, font });
      page.drawText(item.quantity.toString(), { x: 300, y: yOffset, size: 10, font });
      page.drawText(`$${item.rate.toFixed(2)}`, { x: 350, y: yOffset, size: 10, font });
      page.drawText(`$${amount.toFixed(2)}`, { x: 500, y: yOffset, size: 10, font });
      yOffset -= 20;
    });

    // Subtotal, Discount, and Total
    const discount = subtotal * (invoiceData.discount / 100);
    const total = subtotal - discount;

    page.drawText('Subtotal', { x: 400, y: yOffset - 20, size: 10, font: boldFont });
    page.drawText(`$${subtotal.toFixed(2)}`, { x: 500, y: yOffset - 20, size: 10, font });
    page.drawText(`Discount (${invoiceData.discount}%)`, { x: 400, y: yOffset - 40, size: 10, font: boldFont });
    page.drawText(`$${discount.toFixed(2)}`, { x: 500, y: yOffset - 40, size: 10, font });
    page.drawText('Total', { x: 400, y: yOffset - 60, size: 12, font: boldFont });
    page.drawText(`$${total.toFixed(2)}`, { x: 500, y: yOffset - 60, size: 12, font: boldFont });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    try {
        const downloadURL = await uploadInvoicePDF(clientId, invoiceId, pdfBlob);
        
        return downloadURL;
    } catch (error) {
        console.error('Error saving PDF to Firebase Storage:', error);
        throw error;
    }
};