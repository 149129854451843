import { useQuery } from "@tanstack/react-query";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
} from "firebase/firestore";

interface RevenueData {
  totalRevenue: { [key: string]: { [key: string]: number } };
  revenueByClient: {
    [key: string]: {
      companyName: string;
      revenue: { [year: string]: { [month: string]: number } };
    };
  };
}

interface ExpenseData {
  totalExpenses: { [key: string]: { [key: string]: number } };
  expensesByClient: {
    [key: string]: {
      companyName: string;
      expenses: { [year: string]: { [month: string]: number } };
    };
  };
}

interface AdminStatistics {
  revenueData: RevenueData;
  expenseData: ExpenseData;
  stats: {
    totalRevenue: number;
    totalExpenses: number;
    totalClients: number;
    totalProjects: number;
    averageProjectValue: number;
    totalProjectAssignments: number;
    totalBudgetedHours: number;
    activeFreelancers: number;
    totalFreelancers: number;
  };
}

const getAdminStatistics = async (): Promise<AdminStatistics> => {
  const db = getFirestore();
  const clientsRef = collection(db, "clients");
  const clientsSnapshot = await getDocs(query(clientsRef));

  const revenueData: RevenueData = { totalRevenue: {}, revenueByClient: {} };
  const expenseData: ExpenseData = { totalExpenses: {}, expensesByClient: {} };
  let totalProjectAssignments = 0;
  let totalBudgetedHours = 0;
  const activeFreelancers = new Set<string>();
  const projectSet = new Set<string>();

  for (const clientDoc of clientsSnapshot.docs) {
    const clientData = clientDoc.data() as Client;
    const paymentsRef = collection(clientDoc.ref, "payments");
    const paymentsSnapshot = await getDocs(query(paymentsRef));
    const assignmentsRef = collection(clientDoc.ref, "projectAssignments");
    const assignmentsSnapshot = await getDocs(query(assignmentsRef));

    revenueData.revenueByClient[clientDoc.id] = {
      companyName: clientData.companyName,
      revenue: {},
    };
    expenseData.expensesByClient[clientDoc.id] = {
      companyName: clientData.companyName,
      expenses: {},
    };

    paymentsSnapshot.forEach((paymentDoc) => {
      const payment = paymentDoc.data() as Payment;
      const year = new Date(payment.processedDate || "")
        .getFullYear()
        .toString();
      const month = new Date(payment.processedDate || "").getMonth().toString();

      if (!revenueData.totalRevenue[year]) revenueData.totalRevenue[year] = {};
      revenueData.totalRevenue[year][month] =
        (revenueData.totalRevenue[year][month] || 0) +
        parseFloat(payment.amount.toString());

      if (!revenueData.revenueByClient[clientDoc.id].revenue[year]) {
        revenueData.revenueByClient[clientDoc.id].revenue[year] = {};
      }
      if (!revenueData.revenueByClient[clientDoc.id].revenue[year][month]) {
        revenueData.revenueByClient[clientDoc.id].revenue[year][month] = 0;
      }
      revenueData.revenueByClient[clientDoc.id].revenue[year][month] +=
        parseFloat(payment.amount.toString());
    });

    assignmentsSnapshot.forEach((assignmentDoc) => {
      const assignment = assignmentDoc.data() as ProjectAssignment;
      totalProjectAssignments++;
      totalBudgetedHours += assignment.budgetedHours || 0;
      projectSet.add(assignment.projectId);

      const now = new Date();
      if (assignment.startDate) {
        const startDate = new Date(assignment.startDate);
        const endDate = assignment.endDate
          ? new Date(assignment.endDate)
          : null;
        if (startDate <= now && (!endDate || endDate >= now)) {
          activeFreelancers.add(assignment.engineerId);
        }
      }

      if (assignment.amountPaid) {
        const year = assignment.paymentDate
          ? new Date(assignment.paymentDate).getFullYear().toString()
          : "";
        const month = assignment.paymentDate
          ? new Date(assignment.paymentDate).getMonth().toString()
          : "";

        if (year && month) {
          if (!expenseData.totalExpenses[year])
            expenseData.totalExpenses[year] = {};
          expenseData.totalExpenses[year][month] =
            (expenseData.totalExpenses[year][month] || 0) +
            assignment.amountPaid;

          if (!expenseData.expensesByClient[clientDoc.id].expenses[year]) {
            expenseData.expensesByClient[clientDoc.id].expenses[year] = {};
          }
          if (
            !expenseData.expensesByClient[clientDoc.id].expenses[year][month]
          ) {
            expenseData.expensesByClient[clientDoc.id].expenses[year][month] =
              0;
          }
          expenseData.expensesByClient[clientDoc.id].expenses[year][month] +=
            assignment.amountPaid;
        }
      }
    });
  }

  const freelancersRef = collection(db, "users");
  const freelancersQuery = query(
    freelancersRef,
    where("user_type", "==", "freelancer")
  );
  const freelancersSnapshot = await getDocs(freelancersQuery);

  const totalFreelancers = freelancersSnapshot.size;

  const currentYear = new Date().getFullYear().toString();
  const totalRevenue = Object.values(
    revenueData.totalRevenue[currentYear] || {}
  ).reduce((sum, value) => sum + value, 0);
  const totalExpenses = Object.values(
    expenseData.totalExpenses[currentYear] || {}
  ).reduce((sum, value) => sum + value, 0);

  return {
    revenueData,
    expenseData,
    stats: {
      totalRevenue,
      totalExpenses,
      totalClients: clientsSnapshot.size,
      totalProjects: projectSet.size,
      averageProjectValue:
        projectSet.size > 0 ? totalRevenue / projectSet.size : 0,
      totalProjectAssignments,
      totalBudgetedHours,
      activeFreelancers: activeFreelancers.size,
      totalFreelancers,
    },
  };
};

export const useAdminStatistics = () => {
  return useQuery({
    queryKey: ["adminStatistics"], 
    queryFn: getAdminStatistics,
    staleTime: 1000 * 60 * 5, // 5 minutes of stale time
    refetchOnWindowFocus: false, // Disable refetching when window is focused
    refetchOnMount: false, // Prevents refetch on mount if data is fresh
  });
};
