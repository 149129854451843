import forge from "node-forge";
import { freetechPaymentsPublicKey } from "config";

export function encryptPayload(payload: any): string {
  
  try {
    const publicKey = forge.pki.publicKeyFromPem(freetechPaymentsPublicKey);
    const encryptedPayload = publicKey.encrypt(JSON.stringify(payload), "RSA-OAEP", {
      md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encryptedPayload);
  } catch (error) {
    console.error("Failed to encrypt payload:", error);
    throw new Error("Failed to encrypt payload");
  }
}