import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { setJwtToken } from 'core/setJwtToken';
import { getUserProfile } from './getUserProfile';

export const handleLoginWithGoogle = async (
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setUserInfo: React.Dispatch<any>,
) => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const firebaseUserProfile = await getUserProfile(user.uid);

    if (user) {
      const token = await user.getIdToken();
      setJwtToken(token);

      if (!firebaseUserProfile) {
        await setDoc(doc(db, `users/${user.uid}`), {
          email: user.email,
          firstName: user.displayName?.split(' ')[0] || '',
          lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
          user_type: 'non-admin',
          is_active: false,
          email_verified: user.emailVerified,
        }, { merge: true });
      }

      setUserInfo(firebaseUserProfile);
      setIsAuthenticated(true);
      return firebaseUserProfile;
    } else {
      console.error('Failed to sign in with Google');
      return false;
    }
  } catch (error) {
    console.error('Error logging in with Google:', error);
    return false;
  }
};