import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { DatePicker } from "@mui/x-date-pickers-pro";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import TimerIcon from "@mui/icons-material/Timer";
import EditIcon from "@mui/icons-material/EditCalendar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { useTimesheet } from "hooks/shared/timesheet/useTimesheet";
import { useAuth } from "contexts/AuthContext";
import { DateTime } from "luxon";
import { calculateDuration, formatDuration } from "hooks/shared/timesheet/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocalStorageState } from "@toolpad/core";
import { useTheme } from "@mui/material/styles";

interface TimeTrackerInputProps {
  activeProjectAssignments: ProjectAssignment[];
}

const TimeTrackerInput: React.FC<TimeTrackerInputProps> = ({
  activeProjectAssignments,
}) => {
  const [view, setView] = useLocalStorageState("timeTrackingMethod", null);
  const [newEntry, setNewEntry] = useState<Partial<Timesheet>>({
    projectAssignmentId: "",
    description: "",
    date: DateTime.now().toISODate(),
    startTime: DateTime.now().toISO(),
    endTime: DateTime.now().toISO(),
    billable: true,
    submitted: false,
  });
  const [isTimedEntry, setIsTimedEntry] = useState(view === "timer");
  const [expandedNotes, setExpandedNotes] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);
  const [manualDuration, setManualDuration] = useState<string>("00:00:00");

  const { userInfo } = useAuth();
  const userId = userInfo?.id || "";
  const {
    getTimesheets,
    createTimesheet,
    timesheet,
    startTimer,
    stopTimer,
    timerValue,
    isTimerActive,
    isSaving,
    updateTimesheet
  } = useTimesheet(userId);

  const { refetch: refetchTimesheets } = getTimesheets({ userId });

  const updateManualDuration = useCallback(() => {
    if (newEntry.startTime && newEntry.endTime) {
      const durationInSeconds = calculateDuration(
        newEntry.startTime,
        newEntry.endTime
      );
      setManualDuration(formatDuration(durationInSeconds));
    }
  }, [newEntry.startTime, newEntry.endTime]);

  useEffect(() => {
    if (!isTimedEntry) {
      updateManualDuration();
    }
  }, [isTimedEntry, updateManualDuration]);

  const handleInputChange = (field: keyof Timesheet, value: any) => {
    
    setNewEntry((prev) => {
      const updatedEntry = { ...prev, [field]: value };

      if (field === "date") {
        const newDate = DateTime.fromISO(value);
        const currentStartTime = DateTime.fromISO(prev.startTime || "");
        const currentEndTime = DateTime.fromISO(prev.endTime || "");

        const newStartTime = newDate
          .set({
            hour: currentStartTime.hour,
            minute: currentStartTime.minute,
            second: currentStartTime.second,
          })
          .toISO();

        const newEndTime = newDate
          .set({
            hour: currentEndTime.hour,
            minute: currentEndTime.minute,
            second: currentEndTime.second,
          })
          .toISO();

        if (newStartTime) updatedEntry.startTime = newStartTime;
        if (newEndTime) updatedEntry.endTime = newEndTime;
      }

      if (field === "projectAssignmentId") {
        const selectedAssignment = activeProjectAssignments.find(
          (assignment) => assignment.id === value
        );
        if (selectedAssignment) {
          updatedEntry.clientId = selectedAssignment.clientId;
          updatedEntry.projectId = selectedAssignment.projectId;
        }
      }

      return updatedEntry;
    });

    if (field === "startTime" || field === "endTime" || field === "date") {
      updateManualDuration();
    }
  };

  const handleAddEntry = async () => {
    if (!newEntry.projectAssignmentId) {
      setError("Project Assignment is required");
      setShowError(true);
      return;
    }

    if (
      newEntry.projectAssignmentId &&
      newEntry.startTime &&
      newEntry.endTime &&
      newEntry.date
    ) {
      const duration = calculateDuration(newEntry.startTime, newEntry.endTime);
      await createTimesheet.mutateAsync({
        ...newEntry,
        userId: userInfo!.id,
        duration,
        billable: newEntry.billable || true,
        submitted: false,
        description: newEntry.description || "",
        startTime: newEntry.startTime,
        endTime: newEntry.endTime,
        newEntry: newEntry.projectAssignmentId,
      } as Omit<Timesheet, "id">);
      setNewEntry({
        projectAssignmentId: "",
        description: "",
        date: DateTime.now().toISODate(),
        startTime: DateTime.now().toISO(),
        endTime: DateTime.now().plus({ hours: 1 }).toISO(),
        billable: true,
        submitted: false,
      });
      refetchTimesheets();
    }
    clearError();
  };

  const handleTimerOrManualEntryChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null && newView !== view) {
      setIsTimedEntry(newView === "timer");
      if (isTimerActive) {
        handleStopTimer();
      }
      setView(newView);
    }
  };

  const handleStartTimer = async () => {
    if (!newEntry.projectAssignmentId) {
      setError("Please select a project assignment before starting the timer.");
      setShowError(true);
      return;
    }

    try {
      
      await startTimer(newEntry as Omit<Timesheet, "id">);
      clearError();
    } catch (error) {
      console.error("Error starting timer:", error);
      setError("Failed to start timer. Please try again.");
      setShowError(true);
    }
  };

  const handleStopTimer = async () => {
    try {
      const result = await stopTimer();
      if (result) {
        refetchTimesheets();
         
        updateTimesheet.mutateAsync({
          id: timesheet?.id,
          description: newEntry.description,
        });
        setNewEntry({
          projectAssignmentId: "",
          description: "",
          date: DateTime.now().toISODate(),
          startTime: DateTime.now().toISO(),
          endTime: DateTime.now().toISO(),
          billable: true,
          submitted: false,
          duration: 0,
        });
      }
    } catch (error) {
      console.error(error);
      setError("Error stopping timer or adding time entry");
      setShowError(true);
    }
  };

  const formatTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  };

  const inputHeight = 56; // Define a consistent height for all inputs

  const handleProjectAssignmentChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    
    
    setNewEntry({ ...newEntry, projectAssignmentId: value });
    handleInputChange("projectAssignmentId", value);
    clearError();
  };

  const clearError = () => {
    setError(null);
    setShowError(false);
  };

  useEffect(() => {
    
  }, [isTimerActive]);

  const theme = useTheme();

  return (
    <>
      <Paper
        elevation={3}
        sx={{ p: 2, mb: 2, position: "sticky", top: 80, zIndex: 1000 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <FormControl
            sx={{ flexGrow: 1, minWidth: 200 }}
            error={!!error && !timesheet?.projectAssignmentId}
          >
            <InputLabel id="project-assignment-label">
              Assignment
            </InputLabel>
            <Select
              labelId="project-assignment-label"
              value={
                timesheet?.projectAssignmentId || newEntry.projectAssignmentId
              }
              onChange={handleProjectAssignmentChange}
              fullWidth
              disabled={isTimerActive}
            >
              {activeProjectAssignments.map((assignment) => (
                <MenuItem key={assignment.id} value={assignment.id}>
                  {assignment.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!isTimedEntry && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <DatePicker
                label="Date"
                value={newEntry.date ? DateTime.fromISO(newEntry.date) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    const date = newValue as DateTime;
                    handleInputChange("date", date.toISODate());
                  }
                }}
                sx={{
                  width: 150,
                  "& .MuiInputBase-root": { height: inputHeight },
                }}
              />
              <MultiInputTimeRangeField
                value={[
                  newEntry.startTime
                    ? DateTime.fromISO(newEntry.startTime)
                    : null,
                  newEntry.endTime ? DateTime.fromISO(newEntry.endTime) : null,
                ]}
                onChange={(newValue) => {
                  const [start, end] = newValue;
                  if (start && end) {
                    handleInputChange("startTime", start.toISO());
                    handleInputChange("endTime", end.toISO());
                  }
                }}
                sx={{
                  width: 220,
                  "& .MuiInputBase-root": { height: inputHeight },
                }}
                ampm={false}
                format="HH:mm"
              />
            </Box>
          )}

          <IconButton
            onClick={() => handleInputChange("billable", !newEntry.billable)}
            sx={{
              width: inputHeight,
              height: inputHeight,
              border: "1px solid",
              borderColor: "divider",
              color: newEntry.billable
                ? theme.palette.success.main
                : theme.palette.text.secondary,
            }}
          >
            {newEntry.billable ? <AttachMoneyIcon /> : <MoneyOffIcon />}
          </IconButton>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                width: 110,
                height: inputHeight,
              }}
            >
              <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                {isTimedEntry
                  ? isTimerActive && timesheet?.startTime
                    ? `Started: ${DateTime.fromISO(timesheet.startTime).toFormat("HH:mm:ss")}`
                    : "Timer"
                  : "Duration"}
              </Typography>
              <Typography variant="h6">
                {isTimedEntry ? timerValue : manualDuration}
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={
                isTimedEntry ? (
                  isTimerActive ? (
                    isSaving ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <StopIcon />
                    )
                  ) : (
                    <PlayArrowIcon />
                  )
                ) : (
                  <AddIcon sx={{ mr: 1 }} />
                )
              }
              onClick={
                isTimedEntry
                  ? isTimerActive
                    ? handleStopTimer
                    : handleStartTimer
                  : handleAddEntry
              }
              color={isTimedEntry && isTimerActive ? "secondary" : "primary"}
              disabled={isSaving}
              sx={{
                width: 110,
                height: inputHeight,
                "& .MuiButton-startIcon": {
                  marginRight: "-4px",
                },
              }}
            >
              {isTimedEntry
                ? isTimerActive
                  ? isSaving
                    ? "Saving..."
                    : "Stop"
                  : "Start"
                : "Add"}
            </Button>
          </Box>

          <ToggleButtonGroup
            orientation="vertical"
            value={view}
            exclusive
            onChange={handleTimerOrManualEntryChange}
            sx={{
              height: inputHeight,
              "& .MuiToggleButton-root": { padding: "4px" },
            }}
          >
            <ToggleButton value="manual" aria-label="manual" size="small">
              <EditIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="timer" aria-label="timer" size="small">
              <TimerIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Paper>

      <Accordion
        expanded={expandedNotes}
        onChange={() => setExpandedNotes(!expandedNotes)}
        sx={{ mb: 2, boxShadow: "none", "&:before": { display: "none" } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="Description"
            value={newEntry.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setShowError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TimeTrackerInput;
