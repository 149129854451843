import React, { useState } from "react";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Snackbar,
  Alert,
  useTheme,
  alpha,
  Chip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdminClients } from "hooks/admin/useAdminClients";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const ClientManagement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { clients, isLoading, isError, addClient, updateClient, deleteClient } =
    useAdminClients();
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [formData, setFormData] = useState<Partial<Client>>({
    id: "",
    companyName: "",
    pointOfContactName: "",
    email: "",
    phone: "",
    location: "",
    size: "",
    status: "opportunity",
    introductionDate: new Date().toISOString(),
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (
    e: SelectChangeEvent<"opportunity" | "active" | "inactive">
  ) => {
    setFormData({
      ...formData,
      status: e.target.value as "opportunity" | "active" | "inactive",
    });
  };

  const handleSubmit = async () => {
    if (
      !formData.companyName?.trim() ||
      !formData.pointOfContactName?.trim() ||
      !formData.email?.trim() ||
      !formData.phone?.trim() ||
      !formData.location?.trim() ||
      !formData.size?.trim() ||
      !formData.status
    ) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields",
        severity: "error",
      });
      return;
    }

    try {
      if (editMode && selectedClient) {
        await updateClient.mutateAsync({
          id: selectedClient.id!,
          client: formData,
        });
        setSnackbar({
          open: true,
          message: "Client updated successfully",
          severity: "success",
        });
      } else {
        await addClient.mutateAsync(formData);
        setSnackbar({
          open: true,
          message: "Client added successfully",
          severity: "success",
        });
      }
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error("Error saving client: ", error);
      setSnackbar({
        open: true,
        message: "Error saving client",
        severity: "error",
      });
    }
  };

  const handleDeleteClient = async (id: string) => {
    try {
      await deleteClient.mutateAsync(id);
      setSnackbar({
        open: true,
        message: "Client deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting client: ", error);
      setSnackbar({
        open: true,
        message: "Error deleting client",
        severity: "error",
      });
    }
  };

  const handleOpenDialog = (client?: Client) => {
    if (client) {
      setEditMode(true);
      setSelectedClient(client);
      setFormData(client);
    } else {
      setEditMode(false);
      resetForm();
    }
    setOpen(true);
  };

  const resetForm = () => {
    setFormData({
      id: "",
      companyName: "",
      pointOfContactName: "",
      email: "",
      phone: "",
      location: "",
      size: "",
      status: "opportunity",
      introductionDate: new Date().toISOString(),
    });
    setSelectedClient(null);
  };

  const handleManageClient = (client: Client) => {
    navigate(`/admin/client-management/${client.id}`);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "active":
        return "success";
      case "opportunity":
        return "warning";
      case "inactive":
        return "error";
      default:
        return "default";
    }
  };

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography color="error">
            Error loading clients. Please try again later.
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "32px",
          }}
        >
          <Typography variant="h4" color="primary">
            Client Management
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
            startIcon={<AddIcon />}
          >
            Add Client
          </Button>
        </Box>

        <Grid container spacing={3}>
          {clients?.map((client) => (
            <Grid item xs={12} sm={6} md={4} key={client.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: theme.shadows[10],
                  },
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6" component="div" color="primary">
                      {client.companyName}
                    </Typography>
                    <Chip
                      label={client.status}
                      color={getStatusColor(client.status)}
                      size="small"
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Contact:</strong> {client.pointOfContactName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Email:</strong> {client.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Phone:</strong> {client.phone}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Location:</strong> {client.location}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Size:</strong> {client.size}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "space-between", padding: "16px" }}
                >
                  <Button
                    size="small"
                    onClick={() => handleOpenDialog(client)}
                    startIcon={<EditIcon />}
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleManageClient(client)}
                    startIcon={<ManageAccountsIcon />}
                    color="secondary"
                  >
                    Manage
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{editMode ? "Edit Client" : "Add New Client"}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
            <TextField
              autoFocus
              name="companyName"
              label="Company Name"
              fullWidth
              value={formData.companyName}
              onChange={handleInputChange}
            />
            <TextField
              name="pointOfContactName"
              label="Point of Contact Name"
              fullWidth
              value={formData.pointOfContactName}
              onChange={handleInputChange}
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              value={formData.phone}
              onChange={handleInputChange}
            />
            <TextField
              name="location"
              label="Location"
              fullWidth
              value={formData.location}
              onChange={handleInputChange}
            />
            <TextField
              name="size"
              label="Size"
              fullWidth
              value={formData.size}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={formData.status as "opportunity" | "active" | "inactive"}
                onChange={handleStatusChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="opportunity">Opportunity</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClientManagement;
