import { collection, query, getDocs, getFirestore } from "firebase/firestore";

const db = getFirestore();

export const loadAllClients = async (): Promise<Client[]> => {
  const q = query(collection(db, "clients"));
  const querySnapshot = await getDocs(q);
  const clientsData: Client[] = [];

  for (const doc of querySnapshot.docs) {
    const clientData = { id: doc.id, ...doc.data() } as Client;
    const projectsSnapshot = await getDocs(collection(doc.ref, "projects"));
    const projectsData: Project[] = projectsSnapshot.docs.map(
      (projectDoc) =>
        ({
          id: projectDoc.id,
          ...projectDoc.data(),
        } as Project)
    );
    clientData.projects = projectsData;
    clientsData.push(clientData);
  }

  return clientsData;
};
