export const formatDateStringToPretty = (dateString: string | null) => {
  if (!dateString) return "Not set";
  const date = new Date(dateString);
  // Add timezone offset to handle date correctly
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
