import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import DashboardLayout from "router/layouts/CoreLayout/CoreLayout";
import { useTheme } from "@mui/material/styles";
import RevenueChart from "./components/RevenueChart";
import ClientRevenueChart from "./components/ClientRevenueChart";
import RevenueByClientPieChart from "./components/RevenueByClientPieChart";
import { useAdminStatistics } from "hooks/admin";

interface RevenueData {
  totalRevenue: { [key: string]: { [key: string]: number } };
  revenueByClient: {
    [key: string]: {
      companyName: string;
      revenue: { [year: string]: { [month: string]: number } };
    };
  };
}

interface ExpenseData {
  totalExpenses: { [key: string]: { [key: string]: number } };
  expensesByClient: {
    [key: string]: {
      companyName: string;
      expenses: { [year: string]: { [month: string]: number } };
    };
  };
}

const SuperAdminHome: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [revenueData, setRevenueData] = useState<RevenueData>({
    totalRevenue: {},
    revenueByClient: {},
  });
  const [expenseData, setExpenseData] = useState<ExpenseData>({
    totalExpenses: {},
    expensesByClient: {},
  });
  const theme = useTheme();
  const [stats, setStats] = useState({
    totalRevenue: 0,
    totalExpenses: 0,
    totalClients: 0,
    totalProjects: 0,
    averageProjectValue: 0,
    totalProjectAssignments: 0,
    totalBudgetedHours: 0,
    activeFreelancers: 0,
    totalFreelancers: 0,
  });
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );


  const { data, isLoading } = useAdminStatistics();
  useEffect(() => {
    if (data) {
      setStats(data.stats);
      setRevenueData(data.revenueData);
      setExpenseData(data.expenseData);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    calculateStats();
  }, [revenueData, expenseData, selectedYear]);

  const calculateStats = () => {
    const yearRevenueData = revenueData.totalRevenue[selectedYear.toString()] || {};
    const yearExpenseData = expenseData.totalExpenses[selectedYear.toString()] || {};

    const totalRevenue = Object.values(yearRevenueData).reduce(
      (sum, value) => sum + (typeof value === "number" ? value : 0),
      0
    );
    const totalExpenses = Object.values(yearExpenseData).reduce(
      (sum, value) => sum + (typeof value === "number" ? value : 0),
      0
    );

    const clientSet = new Set(Object.keys(revenueData.revenueByClient));
    const projectSet = new Set();

    setStats((prevStats) => ({
      ...prevStats,
      totalRevenue,
      totalExpenses,
      totalClients: clientSet.size,
      totalProjects: projectSet.size,
      averageProjectValue: projectSet.size > 0 ? totalRevenue / projectSet.size : 0,
    }));
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  if (loading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            SuperAdmin Dashboard
          </Typography>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              label="Year"
              onChange={handleYearChange}
            >
              {[...Array(5)].map((_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Typography variant="body1" sx={{ marginBottom: 4, color: "#555" }}>
          Overview of all client revenue and project statistics.
        </Typography>
        <Grid container spacing={4} sx={{ marginBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.success.light,
                  }}
                >
                  <Typography variant="h6" color="success.contrastText">
                    $
                    {stats.totalRevenue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography variant="body2" color="success.contrastText">
                    Total Revenue
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.error.light,
                  }}
                >
                  <Typography variant="h6" color="error.contrastText">
                    $
                    {stats.totalExpenses.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography variant="body2" color="error.contrastText">
                    Total Expenses
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.primary.light,
                  }}
                >
                  <Typography variant="h6" color="primary.contrastText">
                    {stats.totalClients}
                  </Typography>
                  <Typography variant="body2" color="primary.contrastText">
                    Total Clients
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.secondary.light,
                  }}
                >
                  <Typography variant="h6" color="secondary.contrastText">
                    {stats.totalProjects}
                  </Typography>
                  <Typography variant="body2" color="secondary.contrastText">
                    Total Projects
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.info.light,
                  }}
                >
                  <Typography variant="h6" color="info.contrastText">
                    {stats.totalProjectAssignments}
                  </Typography>
                  <Typography variant="body2" color="info.contrastText">
                    Project Assignments
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.warning.light,
                  }}
                >
                  <Typography variant="h6" color="warning.contrastText">
                    {stats.totalBudgetedHours}
                  </Typography>
                  <Typography variant="body2" color="warning.contrastText">
                    Budgeted Hours
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.info.light,
                  }}
                >
                  <Typography variant="h6" color="info.contrastText">
                    {stats.activeFreelancers}
                  </Typography>
                  <Typography variant="body2" color="info.contrastText">
                    Active Freelancers
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: 2,
                    textAlign: "center",
                    bgcolor: theme.palette.warning.light,
                  }}
                >
                  <Typography variant="h6" color="warning.contrastText">
                    {stats.totalFreelancers}
                  </Typography>
                  <Typography variant="body2" color="warning.contrastText">
                    Total Freelancers
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <RevenueByClientPieChart
              data={revenueData.revenueByClient}
              selectedYear={selectedYear.toString()}
            />
          </Grid>
        </Grid>

        <Box sx={{ height: 400, mb: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
            Revenue and Expenses Overview:
          </Typography>
          <RevenueChart
            revenueData={revenueData.totalRevenue[selectedYear.toString()] || {}}
            expenseData={expenseData.totalExpenses[selectedYear.toString()] || {}}
            selectedYear={selectedYear.toString()}
          />
        </Box>

        <Box sx={{ height: 400, mb: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
            Revenue by Client:
          </Typography>
          <ClientRevenueChart
            data={revenueData.revenueByClient}
            selectedYear={selectedYear.toString()}
          />
        </Box>

        <Box sx={{ height: 400, mb: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
            Expenses by Client:
          </Typography>
          <ClientRevenueChart
            data={expenseData.expensesByClient}
            selectedYear={selectedYear.toString()}
            isExpense
          />
        </Box>
      </Paper>
    </>
  );
};

export default SuperAdminHome;
