import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const fetchProfilePictureUrl = async (userId: string): Promise<string | null> => {
  if (!userId) return null;
  
  const storage = getStorage();
  const storageRef = ref(storage, `users/${userId}/profilePicture`);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error getting profile picture URL:", error);
    return null;
  }
};

export const useProfilePicture = (userId: string) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["profilePicture", userId],
    queryFn: () => fetchProfilePictureUrl(userId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const refetchProfilePicture = async () => {
    await queryClient.invalidateQueries({ queryKey: ["profilePicture", userId] });
    return query.refetch();
  };

  return {
    url: query.data,
    isLoading: query.isLoading,
    error: query.error,
    refetch: refetchProfilePicture
  };
}; 