import { logoNoBackground } from 'assets';

export interface AgreementNotificationTemplateProps {
    name: string;
    url: string;
}

export const agreementNotificationTemplate = ({ name, url }: AgreementNotificationTemplateProps) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Agreement Created - Awaiting Admin Signature</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #000;
                background-color: #fff;
                margin: 0;
                padding: 0;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
            }
            .logo {
                text-align: center;
                margin-bottom: 30px;
            }
            .logo img {
                max-width: 150px;
                height: auto;
            }
            h1 {
                color: #000;
                font-size: 24px;
                margin-bottom: 20px;
                text-align: center;
            }
            .content {
                background-color: #f9f9f9;
                border: 1px solid #d1d1d1;
                padding: 20px;
                border-radius: 10px;
            }
            p {
                color: #333;
                font-size: 16px;
                margin-bottom: 15px;
            }
            .button {
                display: inline-block;
                background-color: #000;
                color: #fff;
                text-decoration: none;
                padding: 10px 30px;
                border-radius: 5px;
                font-size: 16px;
                text-align: center;
            }
            .button:hover {
                background-color: #333;
            }
            .footer {
                text-align: center;
                font-size: 12px;
                color: #999;
                margin-top: 30px;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="logo">
                <img src="${logoNoBackground}" alt="FreeTech Logo">
            </div>
            <h1>Agreement Created - Awaiting Admin Signature</h1>
            <div class="content">
                <p>Hello ${name},</p>
                <p>An agreement has been created for you and is currently awaiting signature from an admin.</p>
                <p>You can view the details of the agreement by clicking the button below:</p>
                <p style="text-align: center;">
                    <a href="${url}" class="button">View Agreement Details</a>
                </p>
                <p>You will receive another notification when an admin has signed the agreement.</p>
            </div>
            <div class="footer">
                <p>This is an automated message. Please do not reply to this email.</p>
            </div>
        </div>
    </body>
    </html>
  `;
};

// export { agreementNotificationTemplate };
