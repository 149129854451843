import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { createInboundAchTransfer } from 'core/increase/ach/createInboundAchTransfer';
import { listExternalAccounts } from 'core/increase/external-accounts/listExternalAccounts';

interface AddMoneyDialogProps {
  open: boolean;
  onClose: () => void;
  mainAccountId: string;
}

const AddMoneyDialog: React.FC<AddMoneyDialogProps> = ({ open, onClose, mainAccountId }) => {
  const [amount, setAmount] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [externalAccounts, setExternalAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchExternalAccounts();
  }, []);

  const fetchExternalAccounts = async () => {
    try {
      const result = await listExternalAccounts({});
      if (result.success) {
        setExternalAccounts(result.result.data);
      } else {
        setError('Failed to fetch external accounts');
      }
    } catch (err) {
      console.error('Error fetching external accounts:', err);
      setError('An error occurred while fetching external accounts');
    }
  };

  const handleAddMoney = async () => {
    setLoading(true);
    setError(null);

    try {
      const selectedAccountDetails = externalAccounts.find(account => account.id === selectedAccount);
      if (!selectedAccountDetails) {
        throw new Error('Selected account not found');
      }

      // Convert amount to pennies
      const amountInPennies = Math.round(parseFloat(amount) * 100);

      const result = await createInboundAchTransfer({
        accountId: mainAccountId,
        amount: -amountInPennies, // Negative amount for inbound transfer, in pennies
        individualName: selectedAccountDetails.account_holder_name,
        accountNumber: selectedAccountDetails.account_number,
        routingNumber: selectedAccountDetails.routing_number,
        companyEntryDescription: 'Add Money',
        invoiceId: `add_money_${Date.now()}`
      });

      if (result.success) {
        onClose();
        // You might want to add a success message or refresh the main account balance here
      } else {
        setError('Failed to add money');
      }
    } catch (err) {
      console.error('Error adding money:', err);
      setError('An error occurred while adding money');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Money</DialogTitle>
      <DialogContent>
        <Typography variant="body2" paragraph>
          Transfer money from your external account to your main account.
        </Typography>
        <TextField
          fullWidth
          label="Amount ($)"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          margin="normal"
          inputProps={{ step: "0.01" }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>From Account</InputLabel>
          <Select
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value as string)}
            label="From Account"
          >
            {externalAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.description || `Account ending in ${account.account_number.slice(-4)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button
          onClick={handleAddMoney}
          variant="contained"
          color="primary"
          disabled={loading || !amount || !selectedAccount}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Money'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMoneyDialog;