import { useState, useEffect, useCallback } from 'react';

export const useEversignSDK = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const initializeSDK = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      
      if (window.eversign) {
        
        setIsLoaded(true);
        resolve();
      } else {
        
        const script = document.createElement('script');
        script.src = 'https://static.eversign.com/js/embedded-signing.js';
        script.async = true;

        const timeoutId = setTimeout(() => {
          const timeoutError = new Error('Eversign SDK load timed out');
          console.error(timeoutError);
          setError(timeoutError);
          reject(timeoutError);
        }, 10000); // 10 second timeout

        script.onload = () => {
          clearTimeout(timeoutId);
          
          if (window.eversign) {
            
            setIsLoaded(true);
            resolve();
          } else {
            const initError = new Error('Eversign SDK failed to initialize properly');
            console.error(initError);
            setError(initError);
            reject(initError);
          }
        };

        script.onerror = (event) => {
          clearTimeout(timeoutId);
          const loadError = new Error('Failed to load Eversign SDK');
          console.error(loadError, event);
          setError(loadError);
          reject(loadError);
        };

        document.body.appendChild(script);
      }
    });
  }, []);

  useEffect(() => {
    
    initializeSDK().catch((err) => {
      console.error('Error in initializeSDK:', err);
      setError(err);
    });

    return () => {
      
      const script = document.querySelector('script[src="https://static.eversign.com/js/embedded-signing.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [initializeSDK]);

  return { isLoaded, error, initializeSDK };
};
