import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Divider,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { useClientOverviewStatistics } from "hooks/admin";

interface ClientStatistics {
  totalEngineers: {
    label: string;
    value: number;
  };
  activeEngineers: {
    label: string;
    value: number;
  };
  totalProjects: {
    label: string;
    value: number;
  };
  activeProjects: {
    label: string;
    value: number;
  };
  completedProjects: {
    label: string;
    value: number;
  };
  totalUtilizedHours: {
    label: string;
    value: number;
  };
  totalEngineerExpenses: {
    // Changed from totalAmountSpent
    label: string;
    value: number;
  };
  totalRevenue: {
    // New field
    label: string;
    value: number;
  };
}

interface ClientDetailsProps {
  client: Client;
  clientId: string;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ client, clientId }) => {
  const [editedClient, setEditedClient] = useState<Client | null>(null);
  const [clientStats, setClientStats] = useState<ClientStatistics | null>(null);
  const [editing, setEditing] = useState(false);
  const { updateClient } = useAdminClient(clientId);

  const { data: clientStatistics, isLoading: isClientStatisticsLoading } = useClientOverviewStatistics(clientId);

  useEffect(() => {
    if (client) {
      setEditedClient(client);
    } 

    if (clientStatistics) {
      setClientStats(clientStatistics);
    }

  }, [client]);

  const getStatusColor = (status: Client['status']) => {
    switch (status) {
      case 'opportunity': return '#3498db';
      case 'active': return '#2ecc71';
      case 'inactive': return '#e74c3c';
      default: return '#95a5a6';
    }
  };

  const getStatColor = (key: string) => {
    switch (key) {
      case 'totalEngineers':
        return 'darkblue'
      case 'activeEngineers':
        return 'blue'; // Blue
      case 'totalProjects':
        return 'purple'
      case 'activeProjects':
        return 'grey'
      case 'completedProjects':
        return 'darkgreen'; // Green  2ecc71
      case 'totalUtilizedHours':
        return 'black'; // Orange
      case 'totalEngineerExpenses':  // Changed from totalAmountSpent
        return '#e74c3c'; // Red
      case 'totalRevenue':  // New case
        return '#2ecc71'; // Purple  9b59b6
      default:
        return '#95a5a6'; // Grey
    }
  };

  const handleEdit = () => {
    setEditing(true);
    setEditedClient(prev => ({
      ...prev!,
      introductionDate: prev?.introductionDate || new Date().toISOString()
    }));
  };

  const handleCancel = () => {
    setEditedClient(client);
    setEditing(false);
  };

  const handleSave = async () => {
    if (editedClient && clientId) {
      try {
        await updateClient.mutateAsync(editedClient);
        setEditing(false);
      } catch (error) {
        console.error("Error updating client:", error);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setEditedClient(prev => prev ? { ...prev, [name]: value } : null);
  };

  const handleStatusChange = (event: SelectChangeEvent<Client['status']>) => {
    const newStatus = event.target.value as Client['status'];
    setEditedClient(prev => prev ? { ...prev, status: newStatus } : null);
    if (client) {
      updateClient.mutate({ ...client, status: newStatus });
    }
  };

  if (!editedClient) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ mb: 4 }}>
      <Box p={4} position="relative">
        <IconButton
          onClick={handleEdit}
          sx={{ position: "absolute", top: 16, right: 16 }}
        >
          <EditIcon />
        </IconButton>
        {editing ? (
          <Box sx={{ position: "absolute", top: 16, right: 16 }}>
            <IconButton onClick={handleSave} sx={{ mr: 1 }}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ position: "absolute", top: 16, right: 16 }}>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
        {editing ? (
          <TextField
            fullWidth
            margin="normal"
            name="companyName"
            label="Company Name"
            value={editedClient.companyName}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        ) : (
          <Typography variant="h4" gutterBottom>
            {client.companyName}
          </Typography>
        )}
        <FormControl variant="standard" sx={{ minWidth: 120, mb: 2 }}>
          <Select
            value={editedClient.status}
            onChange={handleStatusChange}
            name="status"
            renderValue={(selected) => (
              <Chip
                label={selected}
                size="small"
                sx={{
                  backgroundColor: getStatusColor(
                    selected as Client["status"]
                  ),
                  color: "white",
                  fontWeight: "bold",
                  "& .MuiChip-label": { px: 2 },
                }}
              />
            )}
            sx={{
              "& .MuiSelect-select": {
                paddingY: 0,
                border: "none",
                backgroundColor: "transparent",
              },
              "&:before": {
                display: "none",
              },
              "&:after": {
                display: "none",
              },
              "& .MuiSelect-icon": {
                color: "inherit",
              },
            }}
          >
            <MenuItem value="opportunity">Opportunity</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
        <Divider sx={{ my: 3 }} />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {[
              "pointOfContactName",
              "email",
              "phone",
              "location",
              "size",
            ].map((field) => (
              <Box key={field} mb={2}>
                {editing ? (
                  <TextField
                    fullWidth
                    name={field}
                    label={
                      field.charAt(0).toUpperCase() +
                      field
                        .slice(1)
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                    }
                    value={editedClient[field as keyof Client]}
                    onChange={handleChange}
                    size="small"
                    sx={{ mb: 1 }}
                  />
                ) : (
                  <Typography variant="body1">
                    <>
                      <strong>
                        {field.charAt(0).toUpperCase() +
                          field
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        :
                      </strong>{" "}
                      {client[field as keyof Client]}
                    </>
                  </Typography>
                )}
              </Box>
            ))}
            {editing ? (
              <TextField
                fullWidth
                name="introductionDate"
                label="Introduction Date"
                type="date"
                value={
                  editedClient?.introductionDate
                    ? editedClient.introductionDate.split("T")[0]
                    : ""
                }
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            ) : (
              <Typography variant="body1">
                <strong>Introduction Date:</strong>{" "}
                {client.introductionDate
                  ? new Date(client.introductionDate).toLocaleDateString()
                  : "Not set"}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {clientStats && (
              <Grid container spacing={2} pt={2}>
                {Object.entries(clientStats).map(([key, stat]) => (
                  <Grid item xs={12} sm={6} md={3} key={key}>
                    <Paper
                      elevation={3}
                      sx={{ 
                        p: 1.5,
                        textAlign: "center",
                        backgroundColor: getStatColor(key),
                        color: "white",
                        borderRadius: 2,
                        transition:
                          "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: 6,
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", mb: 0.5 }}
                      >
                        {key === "totalEngineerExpenses" ||
                        key === "totalRevenue"
                          ? `$${stat.value.toLocaleString()}`
                          : stat.value.toLocaleString()}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "medium" }}
                      >
                        {stat.label}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ClientDetails;
