import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Snackbar, Paper, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddProjectDialog from "./AddProjectDialog";
import { addProject, removeProject, getClientProjects } from "core/projects";
import ProjectCard from "./ProjectCard";
import { useLocalStorageBoolState } from "core/utils";
import { useAdminClient } from "hooks/admin/useAdminClient";
interface AssociatedProjectsProps {
  client: Client;
}

const AssociatedProjects: React.FC<AssociatedProjectsProps> = ({ client }) => {
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isVisible, setIsVisible] = useLocalStorageBoolState("isAssociatedProjectsVisible", false);

  useEffect(() => {
    loadProjects();
  }, [client.id]);

  const loadProjects = async () => {
    try {
      const clientProjects = await getClientProjects(client.id);
      setProjects(clientProjects);
    } catch (error) {
      console.error("Error loading projects:", error);
      setError("Failed to load projects. Please try again.");
    }
  };

  const handleAddProject = async (projectData: Omit<Project, "id">) => {
    try {
      const newProject = await addProject(client.id, projectData);
      setProjects([...projects, newProject]);
      setOpenProjectDialog(false);
    } catch (error) {
      console.error("Error adding project:", error);
      setError("Failed to add project. Please try again.");
    }
  };

  const handleRemoveProject = async (projectId: string) => {
    try {
      await removeProject(client.id, projectId);
      setProjects(projects.filter(project => project.id !== projectId));
    } catch (error) {
      console.error("Error removing project:", error);
      setError("Failed to remove project. Please try again.");
    }
  };

  const handleUpdateProject = async (updatedProject: Project) => {
    try {
      setProjects(projects.map(p => p.id === updatedProject.id ? updatedProject : p));
    } catch (error) {
      console.error("Error updating project:", error);
      setError("Failed to update project. Please try again.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box>
      <Paper 
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: 'linear-gradient(45deg, #212121 30%, #424242 90%)',
          color: 'white',
        }}
      >
        <Box display="flex" alignItems="center">
          <AssignmentIcon sx={{ fontSize: 40, mr: 2}} />
          <Typography variant="h5" component="h2">Projects</Typography>
          <IconButton onClick={toggleVisibility} sx={{ color: 'white', mr: 1, ml: 3.5 }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Box>
        <Button
          onClick={() => setOpenProjectDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ 
            bgcolor: '#000000',
            color: 'white',
            '&:hover': {
              bgcolor: '#212121',
            },
          }}
        >
          Add Project
        </Button>
      </Paper>
      
      {isVisible && (
        <Box display="flex" justifyContent="center" mt={4} mb={2}>
          {projects.length === 0 && (
            <Typography>No projects found</Typography>
          )}
          <Grid container spacing={3}>
            {projects.map((project) => (
              <Grid item xs={12} key={project.id}>
                <ProjectCard
                  project={project}
                  clientId={client.id}
                  onUpdate={handleUpdateProject}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <AddProjectDialog
        open={openProjectDialog}
        onClose={() => setOpenProjectDialog(false)}
        onAdd={handleAddProject}
        clientId={client.id}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
      />
    </Box>
  );
};

export default AssociatedProjects;
