import { collectionGroup, query, getDocs } from "firebase/firestore"
import { db } from "config/firebase";
import { useQuery } from "@tanstack/react-query";


export const useAdminSignAgreements = () => {
    return useQuery({
      queryKey: ["adminProjectAssignments"], 
      queryFn: getAllProjAssToSign,
      staleTime: 1000 * 60 * 5, // 5 minutes of stale time
      refetchOnWindowFocus: false, // Disable refetching when window is focused
      refetchOnMount: false, // Prevents refetch on mount if data is fresh
    });
  };
  

export const getAllProjAssToSign = async (): Promise<ProjectAssignment[]> => {
    const projectAssignmentsQuery = query(collectionGroup(db, 'projectAssignments'));
    const querySnapshot = await getDocs(projectAssignmentsQuery);
    const projectAssignments = querySnapshot.docs
        .map(doc => ({
            ...doc.data(),
            id: doc.id
        } as ProjectAssignment))
        .filter(assignment => 
            assignment.everSignDocumentStatus === 'awaiting_admin_signature' || 
            assignment.everSignDocumentStatus === 'awaiting_engineer_signature'
        );
    return projectAssignments;
}
