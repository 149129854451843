import {
  getFirestore,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
const db = getFirestore();
import { getClientCompanyName } from "core/clients";
import { getProjectName } from "core/projects";

// Add this function at the top of the file or in a separate utility file
const formatDate = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

export const fetchAllFreelancerProjectAssignments = async (
  freelancerId: string
): Promise<ProjectAssignment[]> => {
  const assignmentsQuery = query(
    collection(db, "users", freelancerId, "projectAssignments")
  );
  const assignmentsSnapshot = await getDocs(assignmentsQuery);

  if (assignmentsSnapshot.empty) {
    
    return [];
  }

  const assignments: ProjectAssignment[] = [];

  for (const assignmentDoc of assignmentsSnapshot.docs) {
    const assignmentData = assignmentDoc.data();
    const projectName = await getProjectName(
      assignmentData.clientId,
      assignmentData.projectId
    );
    const clientName = await getClientCompanyName(assignmentData.clientId);


    // Fetch additional project assignment data
    const projectAssignmentRef = doc(db, "clients", assignmentData.clientId, "projectAssignments", assignmentData.assignmentId);
    const projectAssignmentSnap = await getDoc(projectAssignmentRef);
    const projectAssignmentData = projectAssignmentSnap.data();



    if (!projectAssignmentData) {
      
      continue;
    }

    
    

    assignments.push({
      ...projectAssignmentData,
      displayName: `${projectName} (${formatDate(projectAssignmentData.startDate)} - ${formatDate(projectAssignmentData.endDate)}) #${projectAssignmentRef.id}`,
      id: projectAssignmentRef.id,
      projectId: projectAssignmentData.projectId,
      clientId: projectAssignmentData.clientId,
      status: projectAssignmentData.status,
      startDate: projectAssignmentData.startDate,
      endDate: projectAssignmentData.endDate,
      billableRate: projectAssignmentData.billableRate,
      projectName: projectName,
      clientName: clientName,
      engineerId: freelancerId,
      csaId: projectAssignmentData.csaId || null,
    });
  }

  return assignments;
};
