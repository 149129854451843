import { useLocalStorageState } from "@toolpad/core";

const BOOLEAN_CODEC = {
  parse: (value: string) => value === 'true',
  stringify: (value: boolean) => value.toString(),
};

export function useLocalStorageBoolState(key: string, defaultValue: boolean) {
  return useLocalStorageState(key, defaultValue, { codec: BOOLEAN_CODEC });
}
