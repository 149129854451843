import { Interval, DateTime } from "luxon";

const calculateDuration = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return 0;
  try {
    const start = DateTime.fromISO(startTime);
    const end = DateTime.fromISO(endTime);
    return Interval.fromDateTimes(start, end).length("seconds");
  } catch (error) {
    console.error("Error calculating duration:", error);
    return 0;
  }
};

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60); // Truncate to nearest second
  return [hours, minutes, remainingSeconds]
    .map((v) => v.toString().padStart(2, "0"))
    .join(":");
};

const parseDuration = (durationString: string): number => {
  const [hours, minutes] = durationString.split(":").map(Number);
  return hours * 3600 + minutes * 60;
};

const formatDurationForEdit = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.round(durationInSeconds % 60);
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const adjustEndTime = (startTime: string, newDuration: number): string => {
  const start = DateTime.fromISO(startTime);
  return start.plus({ seconds: newDuration }).toISO() || "";
};

const calculateTotalTimeForWeek = (weekEntries: Timesheet[]): string => {
  const totalSeconds = weekEntries.reduce((total, entry) => {
    return total + (entry.duration || 0);
  }, 0);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toFixed(0).padStart(2, "0")}`;
};
export {
  calculateDuration,
  formatDuration,
  parseDuration,
  formatDurationForEdit,
  adjustEndTime,
  calculateTotalTimeForWeek,
};
