import { functionUrls } from "../functionUrls/functionUrls";
import { getHeaders } from "../getHeaders";

const downloadEversignFinalPDF = async (document_hash: string, audit_trail?: boolean): Promise<File> => {
    const response = await fetch(functionUrls.eversign.downloadFinalPDF.url, {
        method: functionUrls.eversign.downloadFinalPDF.method,
        body: JSON.stringify({ document_hash, audit_trail }),
        headers: getHeaders(),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    return new File([blob], `eversign_document_${document_hash}.pdf`, { type: 'application/pdf' });
}

export { downloadEversignFinalPDF };