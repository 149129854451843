import { updateDocument } from "core/firestore";
import { uploadFile } from "core/storage";

export const addFileToProjectAssignment = async (
  clientId: string,
  assignmentId: string,
  file: File
): Promise<string> => {
  
  const fileUrl = await uploadFile(`clients/${clientId}/projectAssignments/${assignmentId}`, file);
  return fileUrl;
};

