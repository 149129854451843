import { useQuery } from "@tanstack/react-query";
import { collection, getDocs } from "firebase/firestore";

import { db } from "config/firebase";

export const useAdminClientProjects = (clientId: string) => {
  return useQuery<Project[], Error>({
    queryKey: ["adminClientProjects", clientId],
    queryFn: async () => {
      const projectsRef = collection(db, "clients", clientId, "projects");
      const projectsSnapshot = await getDocs(projectsRef);

      const projectsWithCSAs: Project[] = [];

      for (const projectDoc of projectsSnapshot.docs) {
        const project = { id: projectDoc.id, ...projectDoc.data() } as Project;
        
        // Fetch CSAs for this project
        const csaRef = collection(db, "clients", clientId, "projects", project.id, "consultingServicesAgreements");
        const csaSnapshot = await getDocs(csaRef);
        
        const csas: ConsultingServicesAgreement[] = csaSnapshot.docs.map(csaDoc => ({
          id: csaDoc.id,
          ...csaDoc.data(),
          projectId: project.id,
        } as ConsultingServicesAgreement));

        // Add CSAs to the project
        project.consultingServicesAgreements = csas;

        projectsWithCSAs.push(project);
      }

      return projectsWithCSAs;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
