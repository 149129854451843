import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ViewFileDialogProps {
  open: boolean;
  onClose: () => void;
  fileUrl: string;
  fileName: string;
}

const ViewFileDialog: React.FC<ViewFileDialogProps> = ({ open, onClose, fileUrl, fileName }) => {

  

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="lg"
      PaperProps={{
        sx: {
          height: '90vh',
          maxHeight: 'none',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexShrink: 0 }}>
        <Typography variant="h6">{fileName}</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, flexGrow: 1, overflow: 'hidden' }}>
        <iframe
          src={`${fileUrl}`}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title={fileName}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ViewFileDialog;