import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Rating, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';

interface PerformanceReviewDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reviewData: PerformanceReview) => void;
  assignment: ProjectAssignment | null;
  engineerName: string;
  currentUser: { id: string; email: string };
}

const PerformanceReviewDialog: React.FC<PerformanceReviewDialogProps> = ({ 
  open, 
  onClose, 
  onSubmit, 
  assignment, 
  engineerName, 
  currentUser 
}) => {
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    if (rating !== null) {
      const reviewData: PerformanceReview = {
        rating,
        comment,
        reviewDate: new Date().toISOString(),
        reviewedBy: {
          id: currentUser.id,
          email: currentUser.email
        }
      };
      onSubmit(reviewData);
      setRating(null);
      setComment('');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Performance Reviews</DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Project:</strong> {assignment?.projectName || 'N/A'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>CSA:</strong> {assignment?.csaName || 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Engineer:</strong> {engineerName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="body2" gutterBottom>
                    <strong>Start Date:</strong> {assignment?.startDate || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>End Date:</strong> {assignment?.endDate || 'N/A'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        
        {assignment?.performanceReviews && assignment.performanceReviews.length > 0 && (
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>Existing Reviews</Typography>
            {assignment.performanceReviews.map((review, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography fontWeight='500'>{review.reviewedBy.email} - {new Date(review.reviewDate).toLocaleDateString()}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" alignItems="center">
                      <Rating
                        value={review.rating}
                        readOnly
                        icon={<StarIcon fontSize="small" style={{ color: 'gold' }} />}
                        emptyIcon={<StarIcon fontSize="small" style={{ opacity: 0.55 }} />}
                      />
                    </Box>
                    <Typography variant="body2"> {review.comment}</Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}

        <Typography variant="h6" gutterBottom>Add New Review</Typography>
        <Typography variant="body2" gutterBottom>
          Rating
        </Typography>
        <Rating
          name="performance-rating"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          icon={<StarIcon fontSize="small" style={{ color: 'gold' }} />}
          emptyIcon={<StarIcon fontSize="small" style={{ opacity: 0.55 }} />}
        />
        <TextField
          autoFocus
          margin="dense"
          id="comment"
          label="Comment"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={rating === null}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PerformanceReviewDialog;