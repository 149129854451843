import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import ViewFileDialog from 'components/ViewFileDialog';
import { getInvoicePDFUrl } from 'core/invoices';

interface InvoiceListProps {
  invoices: InvoiceData[];
}

const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleViewInvoice = async (invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsViewDialogOpen(true);
    try {
      const url = await getInvoicePDFUrl(invoice.clientId, invoice.id);
      setPdfUrl(url);
    } catch (error) {
      console.error('Error fetching PDF URL:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCloseViewDialog = () => {
    setIsViewDialogOpen(false);
    setSelectedInvoice(null);
    setPdfUrl(null);
  };

  const calculateTotalAmount = (invoice: InvoiceData): number => {
    if (!invoice.items || !Array.isArray(invoice.items)) {
      return 0;
    }
    return invoice.items.reduce((sum: number, item: any) => sum + (item.quantity * item.rate), 0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.clientName}</TableCell>
                <TableCell>{invoice.invoiceDate}</TableCell>
                <TableCell>${calculateTotalAmount(invoice).toFixed(2)}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewInvoice(invoice)}>View Invoice</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedInvoice && pdfUrl && (
        <ViewFileDialog
          open={isViewDialogOpen}
          onClose={handleCloseViewDialog}
          fileUrl={pdfUrl}
          fileName={`Invoice_${selectedInvoice.invoiceNumber}.pdf`}
        />
      )}
    </>
  );
};

export default InvoiceList;