import { addDocument } from "core/firestore";

export const createClientPayment = async (clientId: string, paymentData: Omit<Payment, 'id'>): Promise<Payment> => {
  // const status = new Date(paymentData.dueDate) > new Date() ? 'pending' : 'overdue';
  const newPaymentRef = await addDocument(`clients/${clientId}/payments`, {
    ...paymentData,
    status: paymentData.status || 'pending',
  });
  return { ...paymentData, id: newPaymentRef.id } as Payment;
};
