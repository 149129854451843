import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import InvoiceList from './components/InvoiceList';
import GenerateInvoiceDialog from './components/GenerateInvoiceDialog';
import { getInvoices } from 'core/invoices';
import { createAndDownloadPDF } from '../../../core/invoices/createAndDownloadPDF';

const InvoiceManagement: React.FC = () => {
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState(false);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    const fetchedInvoices = await getInvoices();
    setInvoices(fetchedInvoices);
  };

  const handleGenerateInvoice = () => {
    setIsGenerateDialogOpen(true);
  };

  const handleCloseGenerateDialog = () => {
    setIsGenerateDialogOpen(false);
  };

 

  return (
    <>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Invoice Management
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGenerateInvoice} sx={{ mb: 2 }}>
          Generate New Invoice
        </Button>
        <InvoiceList invoices={invoices} />
        <GenerateInvoiceDialog 
          open={isGenerateDialogOpen} 
          onClose={handleCloseGenerateDialog} 
          onSubmit={(data) => {
            createAndDownloadPDF(data);
            fetchInvoices(); // Refresh the invoice list after generating a new invoice
          }}
        />
      </Box>
    </>
  );
};

export default InvoiceManagement;