import { httpCodes } from "core/httpCodes";
import { getHeaders, functionUrls } from "core";

export const createFirebaseUser = async (
  userData: CreateUserType
): Promise<string | undefined> => {
  const res = await fetch(functionUrls.users.createFirebaseUser.url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });

  const data = await res.json();

  const userId = data?.userId;

  if (userId) {
    return data;
  } else {
    const status = res.status;
    // const message = httpCodes[status] || 'An error occurred, please contact Chase.'
    return undefined;
  }
};
