
import { addDocument } from "core/firestore";
import { uploadFile } from "core/storage";

export const uploadCSA = async (
    clientId: string,
    projectId: string,
    csaData: Omit<ConsultingServicesAgreement, "id" | "fileUrl">,
    file: File
  ): Promise<ConsultingServicesAgreement> => {
    const newCSARef = await addDocument(`clients/${clientId}/projects/${projectId}/consultingServicesAgreements`, csaData);
    const fileUrl = await uploadFile(`clients/${clientId}/projects/${projectId}/csas/${newCSARef.id}`, file);
  
    return {
      id: newCSARef.id,
      ...csaData,
      fileUrl,
    };
  };