import { agreementNotificationTemplate, AgreementNotificationTemplateProps } from './agreementNotificationTemplate';
import { miscNotificationTemplate, MiscNotificationTemplateProps } from './miscNotificationTemplate';
import { adminProjectAssignmentStatusUpdated, AdminProjectAssignmentStatusUpdatedProps } from './adminProjectAssignmentStatusUpdated';
import { paSignedByAdminEngineerUpdate, PASignedByAdminEngineerUpdateProps } from './paSignedByAdminEngineerUpdate';
import { timesheetSubmittedNotification, TimesheetSubmittedNotificationProps } from './timesheetSubmittedNotification';

export const templates = {
  agreementNotification: agreementNotificationTemplate as (params: AgreementNotificationTemplateProps) => string,
  miscNotification: miscNotificationTemplate as (params: MiscNotificationTemplateProps) => string,
  adminProjectAssignmentStatusUpdated: adminProjectAssignmentStatusUpdated as (params: AdminProjectAssignmentStatusUpdatedProps) => string,
  paSignedByAdminEngineerUpdate: paSignedByAdminEngineerUpdate as (params: PASignedByAdminEngineerUpdateProps) => string,
  timesheetSubmittedNotification: timesheetSubmittedNotification as (params: TimesheetSubmittedNotificationProps) => string,
} as const;

export type TemplateType = keyof typeof templates;

export type TemplateParams = {
  agreementNotification: AgreementNotificationTemplateProps;
  miscNotification: MiscNotificationTemplateProps;
  adminProjectAssignmentStatusUpdated: AdminProjectAssignmentStatusUpdatedProps;
  paSignedByAdminEngineerUpdate: PASignedByAdminEngineerUpdateProps;
  timesheetSubmittedNotification: TimesheetSubmittedNotificationProps;
};
