import { addDoc, collection } from 'firebase/firestore';
import { db } from 'config/firebase';
import { addEngineerToProjectAssignment } from '../projectAssignments/addEngineerToProjectAssignment';
import { sendMailTemplate } from '../mail/sendMailTemplate';
import { fetchFreelancerDoc } from '../freelancer/fetchFreelancerDoc';

export const createEversignProjectAssignment = async (
  freelancerId: string,
  clientId: string,
  projectId: string,
  projectAssignment:  Omit<ProjectAssignment, 'id'>,
  csaId?: string,
): Promise<ProjectAssignment> => {

  const freelancer = await fetchFreelancerDoc(freelancerId);

  if (!freelancer) {
    throw new Error("Freelancer not found");
  }

  sendMailTemplate(freelancer.email, "New Project Assignment", "agreementNotification", {
    name: freelancer.firstName + " " + freelancer.lastName || "",
    url: "https://portal.freetech.co/sign-agreements",
  });

  try {
    const pa:  Omit<ProjectAssignment, 'id'> = {
      ...projectAssignment,
      clientId,
      csaId: csaId || null,
      projectId,
    };

    const newPa = await addEngineerToProjectAssignment(clientId, freelancerId, pa, null);

    return newPa;
  } catch (error) {
    console.error('Error creating project assignment:', error);
    throw error;
  }
};
