import { getHeaders } from "core/getHeaders";
import { encryptPayload } from "../encryptPayload";
import { increaseFunctionUrls } from "../increaseFunctionUrls";

export const createInboundAchTransfer = async (
  params: typeof increaseFunctionUrls.ach.createInboundAchTransfer.input
): Promise<typeof increaseFunctionUrls.ach.createInboundAchTransfer.output> => {

  const formattedParams = {
    ...params,
    amount: Math.round(params.amount),
  };

  const response = await fetch(increaseFunctionUrls.ach.createInboundAchTransfer.url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ payload: encryptPayload(formattedParams) })
  });

  if (!response.ok) {
    throw new Error("Failed to process ACH transfer");
  }

  return response.json();
};