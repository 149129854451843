import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getProjectAssignmentBlobUrl } from "core/projectAssignments";

export const useAdminProjectAssignmentCard = (clientId: string, assignmentId: string) => {
  const queryClient = useQueryClient();

  const fetchAssignmentAndRelatedData = async (): Promise<{
    assignment: ProjectAssignment;
  }> => {
    // Fetch assignment
    const assignmentRef = doc(db, 'clients', clientId, 'projectAssignments', assignmentId);
    const assignmentSnap = await getDoc(assignmentRef);
    if (!assignmentSnap.exists()) {
      throw new Error('Assignment not found');
    }
    const url = await getProjectAssignmentBlobUrl(clientId, assignmentId);
    let assignment = {...assignmentSnap.data(), id: assignmentId, fileUrl: url } as ProjectAssignment;

    // Fetch client name
    const clientRef = doc(db, 'clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const clientName = clientSnap.exists() ? clientSnap.data().companyName : null;

    // Fetch project name
    const projectRef = doc(db, 'clients', clientId, 'projects', assignment.projectId);
    const projectSnap = await getDoc(projectRef);
    const projectName = projectSnap.exists() ? projectSnap.data().name : null;

    // Fetch CSA name
    let csaName = null;
    if (assignment.projectId && assignment.csaId) {
      const csaRef = doc(db, 'clients', clientId, 'projects', assignment.projectId, 'consultingServicesAgreements', assignment.csaId);
      const csaSnap = await getDoc(csaRef);
      csaName = csaSnap.exists() ? csaSnap.data().name : null;
    }

    assignment.id = assignmentId;
    assignment.csaName = csaName;
    assignment.projectName = projectName;
    assignment.clientName = clientName;

    return { assignment };
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['adminProjectAssignment', clientId, assignmentId],
    queryFn: fetchAssignmentAndRelatedData,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const updateAssignment = useMutation({
    mutationFn: async (updatedData: Partial<ProjectAssignment>) => {
      const assignmentRef = doc(db, 'clients', clientId, 'projectAssignments', assignmentId);
      
      try {
        await updateDoc(assignmentRef, updatedData);
        
      } catch (error) {
        console.error('Error updating assignment:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminProjectAssignment', clientId, assignmentId] });
    },
  });

  return {
    assignment: data?.assignment,
    isLoading,
    error,
    updateAssignment: updateAssignment.mutate,
  };
};
