import { getFirestore, collection, addDoc, Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const db = getFirestore();

export const uploadDocument = async (userId: string, file: File, documentData: Partial<FreelancerDocument>) => {
  const storage = getStorage();
  const fileRef = ref(storage, `users/${userId}/documents/${file.name}`);
  
  await uploadBytes(fileRef, file);
  const fileUrl = await getDownloadURL(fileRef);

  const docData = {
    ...documentData,
    uploadDate: Timestamp.now().toDate().toISOString(),
    fileUrl
  };

  const docRef = await addDoc(collection(db, "users", userId, "documents"), docData);
  return { id: docRef.id, ...docData } as FreelancerDocument;
};
