import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Chip, Box, Button, Grid, Divider, useTheme } from '@mui/material';
import { readCSA } from "core/csa/readCSA";
import { getProjectName } from "core/projects";
import { fetchClient } from 'core/clients/fetchClient';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface ProjectAssignmentCardProps {
  assignment: ProjectAssignment;
  onViewFile: (assignment: ProjectAssignment) => void;
  getStatusColor: (status: ProjectAssignmentStatus) => "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

const ProjectAssignmentCard: React.FC<ProjectAssignmentCardProps> = ({ 
  assignment, 
  onViewFile, 
  getStatusColor 
}) => {
  const [projectName, setProjectName] = useState<string | null>(null);
  const [csaName, setCSAName] = useState<string | null>(null);
  const [clientName, setClientName] = useState<string | null | undefined>(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchNames = async () => {
      if (assignment.projectId && assignment.clientId) {
        const fetchedProjectName = await getProjectName(assignment.clientId, assignment.projectId);
        setProjectName(fetchedProjectName);
      }
      if (assignment.csaId && assignment.clientId) {
        const csa = await readCSA(assignment.clientId, assignment.projectId, assignment.csaId);
        setCSAName(csa?.name || null);
      }
      if (assignment.clientId) {
        const fetchedClient = await fetchClient(assignment.clientId)
        setClientName(fetchedClient?.companyName)
      }
    };

    fetchNames();
  }, [assignment]);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Not set';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      transition: 'all 0.3s',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[10],
      },
    }}>
      <CardContent sx={{ flexGrow: 1, position: 'relative', p: 3 }}>
        <Chip 
          label={assignment.status} 
          color={getStatusColor(assignment.status)}
          size="small"
          sx={{ 
            position: 'absolute', 
            top: theme.spacing(2), 
            right: theme.spacing(2),
            fontWeight: 'bold',
          }}
        />
        <Typography variant="h6" gutterBottom sx={{ mb: 2, pr: 8 }}>
          {projectName || assignment.projectName}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Client: {clientName || 'N/A'}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                Start: {formatDate(assignment.startDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                End: {formatDate(assignment.endDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <AttachMoneyIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                Rate: ${assignment.billableRate}/hr
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                Hours: {assignment.utilizedHours || 0}/{assignment.budgetedHours || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic', mb: 2 }}>
          CSA: {csaName || 'N/A'}
        </Typography>
      </CardContent>
      {assignment.fileUrl && (
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => onViewFile(assignment)}
          startIcon={<VisibilityIcon />}
          sx={{ 
            mt: 'auto',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderRadius: 0,
            py: 1.5,
          }}
        >
          View Contract
        </Button>
      )}
    </Card>
  );
};

export default ProjectAssignmentCard;
