import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

interface RevenueChartProps {
  revenueData?: { [key: string]: number };
  expenseData?: { [key: string]: number };
  selectedYear: string;
}

const RevenueChart: React.FC<RevenueChartProps> = ({ revenueData, expenseData, selectedYear }) => {
  const theme = useTheme();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const chartData = [
    {
      label: 'Expenses',
      data: monthNames.map((_, index) => expenseData?.[index.toString()] || 0),
      color: theme.palette.error.main,
    },
    {
      label: 'Revenue',
      data: monthNames.map((_, index) => revenueData?.[index.toString()] || 0),
      color: theme.palette.success.main,
    },
  ];

  return (
    <Box sx={{ display: 'flex', height: 400, width: '100%' }}>
      <Box sx={{ width: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'center', pr: 2 }}>
        {chartData.map((series, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: series.color,
                mr: 1,
                borderRadius: 1,
              }}
            />
            <Typography variant="body2">{series.label}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: '85%' }}>
        <LineChart
          xAxis={[{ 
            data: monthNames,
            scaleType: 'band',
          }]}
          series={chartData.map(series => ({
            data: series.data,
            label: series.label,
            area: true,
            stack: 'total',
            color: series.color,
            valueFormatter: (value: number | null) => `$${value?.toFixed(2)}`,
          }))}
          width={1000}
          height={400}
          sx={{
            '.MuiLineElement-root': {
              strokeWidth: 2,
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default RevenueChart;