import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const db = getFirestore();

export const getUserByEmail = async (email: string) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  
  if (querySnapshot.empty) {
    return null;
  }
  
  const userDoc = querySnapshot.docs[0];
  return {
    id: querySnapshot.docs[0].id,
    ...userDoc.data()
  };
};
