import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Snackbar,
  Paper,
  IconButton,
  Chip,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EngineeringIcon from "@mui/icons-material/Engineering";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import EngineerCard from "./EngineerCard/EngineerCard";
import { AddEngineerDialog } from "./AddEngineerDialog";
import { useLocalStorageBoolState } from "core/utils";
import { useAdminClient } from "hooks/admin/useAdminClient";
interface AssociatedEngineersProps {
  client: Client;
}

const AssociatedEngineers: React.FC<AssociatedEngineersProps> = ({
  client,
}) => {
  const [openEngineerDialog, setOpenEngineerDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedEngineersVisible",
    false
  );
  const [statusFilters, setStatusFilters] = useState<FreelancerStatus[]>([]);


  const [engineers, setEngineers] = useState<Engineer[]>([]);

  useEffect(() => {
    setEngineers(prevEngineers => [...prevEngineers, ...(client.engineers || [])]);
  }, [client.engineers]);

  const { addNestedDocument, updateNestedDocument, deleteNestedDocument } = useAdminClient(client.id);
  if (!engineers) {
    {
      isVisible && (
        <Box display="flex" justifyContent="center" mt={4} mb={2}>
          {/* @ts-ignore */}
          {filteredEngineers && filteredEngineers.length === 0 && (
            <Typography>No engineers found.</Typography>
          )}
        </Box>
      );
    }
  }


  const filteredEngineers = useMemo(() => {
    if (statusFilters.length === 0) return client.engineers || [];
    return (client.engineers || []).filter(engineer => statusFilters.includes(engineer.status));
  }, [client.engineers, statusFilters]);

  if (!engineers) {
    {
      isVisible && (
        <Box display="flex" justifyContent="center" mt={4} mb={2}>
          {filteredEngineers && filteredEngineers.length === 0 && (
            <Typography>No engineers found.</Typography>
          )}
        </Box>
      );
    }
  }

  const handleAddEngineer = async (engineerData: UserInfoType) => {
    try {
      if (engineers && engineers.some((eng) => eng.engineer?.id === engineerData.id)) {
        setError("This engineer is already associated with the client.");
        return;
      }

      addNestedDocument.mutate({
        collectionName: "engineers",
        data: {
          id: engineerData.id,
          associationDate: new Date().toISOString(),
          engineer: engineerData,
        },
      });
    } catch (error) {
      console.error("Error adding engineer:", error);
      setError("Failed to add engineer. Please try again.");
    }
  };

  const handleRemoveEngineer = async (engineerId: string) => {
    try {
      deleteNestedDocument.mutate({
        collectionName: "engineers",
        docId: engineerId,
      });
    } catch (error) {
      console.error("Error removing engineer:", error);
      setError("Failed to remove engineer. Please try again.");
    }
  };



  const handleCloseError = () => {
    setError(null);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getStatusColor = (status: FreelancerStatus) => {
    switch (status) {
      case "ready":
        return "#3498db"; // Blue
      case "active":
        return "#2ecc71"; // Green
      case "inactive":
        return "#e74c3c"; // Red
      default:
        return "#95a5a6"; // Grey
    }
  };

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "linear-gradient(45deg, #757575 30%, #9E9E9E 90%)",
          color: "white",
        }}
      >
        <Box display="flex" alignItems="center">
          <EngineeringIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h5" component="h2" sx={{ mr: 2 }}>
            Engineers
          </Typography>
          <Autocomplete
            multiple
            options={["ready", "active", "inactive"] as FreelancerStatus[]}
            value={statusFilters}
            onChange={(event, newValue) => {
              setStatusFilters(newValue as FreelancerStatus[]);
            }}
            renderInput={(params) =>
              isVisible && (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={
                    statusFilters.length === 0 ? "Status filter..." : ""
                  }
                  sx={{
                    width: 300,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiAutocomplete-input": {
                      color: "white",
                    },
                  }}
                />
              )
            }
            renderTags={(value: FreelancerStatus[], getTagProps) =>
              value.map((option: FreelancerStatus, index: number) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: getStatusColor(option),
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      color: "white",
                    },
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))
            }
          />
          <IconButton onClick={toggleVisibility} sx={{ color: "white", mr: 1 }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Box>
        <Button
          onClick={() => setOpenEngineerDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            bgcolor: "#424242",
            color: "white",
            "&:hover": {
              bgcolor: "#616161",
            },
          }}
        >
          Add Engineer
        </Button>
      </Paper>

      {filteredEngineers.length > 0 && isVisible && (
        <Grid container spacing={3}>
          {filteredEngineers.map((engineer) => (
            <EngineerCard
              key={engineer.id}
              clientId={client.id}
              engineerId={engineer.id}
              onRemove={() => handleRemoveEngineer(engineer.id)}
            />
          ))}
        </Grid>
      )}
      {!isVisible && filteredEngineers && filteredEngineers.length > 0 && (
        <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
          Engineers are hidden. Click the visibility icon to show them.
        </Typography>
      )}
      <AddEngineerDialog
        open={openEngineerDialog}
        onClose={() => setOpenEngineerDialog(false)}
        onAdd={handleAddEngineer}
        client={client}
        currentEngineers={engineers
          ?.map((e) => e.engineer)
          .filter((e): e is Freelancer => e !== undefined) || undefined}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
      />
    </Box>
  );
};

export default AssociatedEngineers;
