export function verifyPasswordRequirements(password: string): boolean {
    
    // Check length
    if (password.length < 7) {
        return false;
    }

    // Check for the presence of an uppercase letter
    const hasUppercase = /[A-Z]/.test(password);
    if (!hasUppercase) {
        return false;
    }

    // Check for the presence of a lowercase letter
    const hasLowercase = /[a-z]/.test(password);
    if (!hasLowercase) {
        return false;
    }

    // Check for the presence of a number
    const hasNumber = /\d/.test(password);
    if (!hasNumber) {
        return false;
    }

    return true;
}
