import { getClientProjects } from "../clients";
import { loadSingleStakeholder } from "./loadSingleStakeholder";

export const fetchStakeholderProjects = async (stakeholderId: string): Promise<Project[]> => {

    const stakeholder = await loadSingleStakeholder(stakeholderId);

    if (!stakeholder) {
        throw new Error('Stakeholder not found');
    }

    const clientIds = stakeholder.stakeholderClientIds || [];

    const projects = await Promise.all(clientIds.map(async (clientId) => {
        return await getClientProjects(clientId);
    }));

    return projects.flat();
};