import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  collectionGroup,
  getDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { loadAllClients } from "../../../core/clients";
import { fetchAllFreelancerProjectAssignments } from "../../../core/freelancer/fetchAllFreelancerProjectAssignments";
import { DateTime } from "luxon";
import { timesheetConverter } from "./timesheetConverter";
import { useState, useCallback, useEffect } from "react";
import { formatDuration } from "./utils";
import { getClientCompanyName } from "../../../core/clients";
import { getProjectName } from "../../../core/projects";



export const useTimesheet = (userId: string) => {
  const queryClient = useQueryClient();
  const [timesheet, setTimesheet] = useState<Timesheet | undefined>(undefined);
  const [timerStartTime, setTimerStartTime] = useState<string | null>(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timerValue, setTimerValue] = useState("00:00:00");
  const [isSaving, setIsSaving] = useState(false);

  const getTimesheets = (filters: Partial<Timesheet>) => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["timesheets", filters],
      queryFn: async () => {
        const timesheetsRef = collection(db, "timesheets").withConverter(
          timesheetConverter
        );
        let q = query(timesheetsRef);

        Object.entries(filters).forEach(([key, value]) => {
          if (value) {
            q = query(q, where(key, "==", value));
          }
        });

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          
          return [];
        }

        const timesheets = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const timesheet = doc.data() as Timesheet;
            const [clientName, projectName] = await Promise.all([
              getClientCompanyName(timesheet.clientId),
              getProjectName(timesheet.clientId, timesheet.projectId),
            ]);
            return {
              ...timesheet,
              clientName,
              projectName,
            };
          })
        );

        return timesheets.sort((a, b) => {
          const dateA = DateTime.fromISO(a.date);
          const dateB = DateTime.fromISO(b.date);
          if (dateA.equals(dateB)) {
            const startTimeA = DateTime.fromISO(a.startTime);
            const startTimeB = DateTime.fromISO(b.startTime);
            return startTimeB.toMillis() - startTimeA.toMillis();
          }
          return dateB.toMillis() - dateA.toMillis();
        });
      },
      staleTime: 5 * 60 * 1000,
    });
  };

  const getTimesheet = useCallback(async (id: string): Promise<Timesheet | null> => {
    const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
    const docSnap = await getDoc(timesheetRef);
    if (docSnap.exists()) {
      const timesheet = docSnap.data() as Timesheet;
      const [clientName, projectName] = await Promise.all([
        getClientCompanyName(timesheet.clientId),
        getProjectName(timesheet.clientId, timesheet.projectId),
      ]);
      return {
        ...timesheet,
        clientName,
        projectName,
      };
    }
    return null;
  }, [db]);

  const filterActiveProjectAssignments = useCallback(
    (assignments: ProjectAssignment[]) => {
      const now = DateTime.now();
      const bufferDays = 7;

      return assignments.filter((assignment) => {


        if (assignment.status !== "in_progress") {
          return false;
        }

        if (!assignment.startDate || !assignment.endDate) {
          return false;
        }

        const startDate = DateTime.fromISO(assignment.startDate);
        const endDate = DateTime.fromISO(assignment.endDate);

        if (!startDate.isValid || !endDate.isValid) {
          return false;
        }

        const isWithinStartBuffer =
          now >= startDate.minus({ days: bufferDays }) &&
          now <= startDate.plus({ days: bufferDays });
        const isWithinEndBuffer =
          now >= endDate.minus({ days: bufferDays }) &&
          now <= endDate.plus({ days: bufferDays });
        const isActive = now >= startDate && now <= endDate;

        return isActive || isWithinStartBuffer || isWithinEndBuffer;
      });
    },
    []
  );

  const getClientsProjectsAndAssignments = () => {
    return useQuery({
      queryKey: ["clientsProjectsAndAssignments", userId],
      queryFn: async () => {
        const clients = await loadAllClients();
        const projectsQuery = collectionGroup(db, "projects");
        const projectsSnapshot = await getDocs(projectsQuery);
        const projects = projectsSnapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() }) as Project
        );
        const projectAssignments =
          await fetchAllFreelancerProjectAssignments(userId);
        
        const activeProjectAssignments = filterActiveProjectAssignments(projectAssignments);
        
        return { clients, projects, projectAssignments: activeProjectAssignments };
      },
      staleTime: 5 * 60 * 1000, // This will prevent refetching until the page is reloaded
    });
  };

  const createTimesheet = useMutation<string, Error, Omit<Timesheet, "id">>({
    mutationFn: async (newTimesheet) => {
      const timesheetsRef = collection(db, "timesheets").withConverter(
        timesheetConverter
      );
      const docRef = await addDoc(timesheetsRef, newTimesheet);
      return docRef.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const updateTimesheet = useMutation<void, Error, Partial<Timesheet>>({
    mutationFn: async ({ id, ...updateData }) => {
      if (!id) throw new Error("Timesheet ID is required for update");
      const timesheetRef = doc(db, "timesheets", id).withConverter(
        timesheetConverter
      );
      await updateDoc(timesheetRef, updateData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const deleteTimesheet = useMutation<void, Error, string>({
    mutationFn: async (id) => {
      await deleteDoc(doc(db, "timesheets", id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const fetchTimerState = async () => {
    if (!timesheet?.id) return null;
    const docRef = doc(db, "timesheets", timesheet.id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? (docSnap.data() as Timesheet) : null;
  };

  const { data: firestoreTimerState, refetch: refetchTimerState } = useQuery({
    queryKey: ["timerState", userId],
    queryFn: fetchTimerState,
    enabled: !!userId && !!timesheet?.id,
    staleTime: 5 * 60 * 1000,
  });

  // New function to update user document with active timer ID
  const updateUserActiveTimer = async (timesheetId: string | null) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { activeTimerId: timesheetId });
  };

  const startTimer = useCallback(
    async (newTimesheet: Omit<Timesheet, "id">) => {
      try {
        if (!userId) {
          throw new Error("User ID is null");
        }
        const now = DateTime.now();
        const createdTimesheetId = await createTimesheet.mutateAsync({
          startTime: now.toISO(),
          endTime: now.toISO(),
          duration: 0,
          userId: userId,
          timerActive: true,
          projectAssignmentId: newTimesheet.projectAssignmentId,
          description: newTimesheet.description || "",
          clientId: newTimesheet.clientId,
          projectId: newTimesheet.projectId,
          date: now.toISODate(),
          billable: newTimesheet.billable || true,
          submitted: false,
          approved: false,
          rejected: false,
          approvedAt: null,
          rejectedAt: null,
          rejectedBy: null,
          approvedBy: null,
        });

        const createdTimesheet: Timesheet = {
          ...newTimesheet,
          id: createdTimesheetId as string,
          startTime: now.toISO(),
          endTime: now.toISO(),
          duration: 0,
          timerActive: true,
        };

        setTimesheet(createdTimesheet);
        setIsTimerActive(true);
        setTimerStartTime(now.toISO());

        // Update user document with active timer ID
        await updateUserActiveTimer(createdTimesheetId);
      } catch (error) {
        console.error("Error starting timer:", error);
        throw error;
      }
    },
    [createTimesheet, userId]
  );

  const stopTimer = useCallback(() => {
    setIsSaving(true);
    try {
      const endTime = DateTime.now();
      if (!timerStartTime) {
        throw new Error("Start time is null");
      }
      const start = DateTime.fromISO(timerStartTime);
      const duration = Math.round(endTime.diff(start).as("seconds"));

      if (timesheet) {
        
        updateTimesheet.mutateAsync({
          id: timesheet.id,
          endTime: endTime.toISO(),
          duration,
          timerActive: false,
        });
      }

      setIsTimerActive(false);
      setTimerStartTime(null);
      setTimesheet(undefined);
      setTimerValue("00:00:00"); // Reset timer value to zero

      // Update user document to remove active timer ID
      updateUserActiveTimer(null);

      refetchTimerState();
      return true;
    } catch (error) {
      console.error("Error stopping timer:", error);
      return false;
    } finally {
      setIsSaving(false);
    }
  }, [timesheet, updateTimesheet, timerStartTime]);

  const fetchActiveTimer = useCallback(async () => {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    const activeTimerId = userDoc.data()?.activeTimerId;

    if (activeTimerId) {
      const activeTimesheet = await getTimesheet(activeTimerId);
      if (activeTimesheet) {
        setTimesheet(activeTimesheet);
        setIsTimerActive(true);
        setTimerStartTime(activeTimesheet.startTime);
      } else {
        await updateUserActiveTimer(null);
      }
    }
  }, [userId]);


  const updateTimerValue = useCallback(() => {
    if (timerStartTime) {
      const diff = DateTime.now().diff(
        DateTime.fromISO(timerStartTime),
        "seconds"
      );
      const newTimerValue = formatDuration(Math.floor(diff.seconds));
      setTimerValue(newTimerValue);
    }
  }, [timerStartTime]);


  useEffect(() => {
    fetchActiveTimer();
  }, [fetchActiveTimer]);
  
  useEffect(() => {
    if (firestoreTimerState?.timerActive) {
      
      setTimerStartTime(firestoreTimerState.startTime);
    }
  }, [firestoreTimerState]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerActive) {
      interval = setInterval(updateTimerValue, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, updateTimerValue]);

  return {
    getTimesheets,
    createTimesheet,
    updateTimesheet,
    deleteTimesheet,
    getClientsProjectsAndAssignments,
    timesheet,
    startTimer,
    stopTimer,
    refetchTimerState,
    updateTimerValue,
    isTimerActive,
    timerValue,
    isSaving,
    setTimerValue,
    fetchActiveTimer, // Add this to allow manual refetch if needed
  };
};
