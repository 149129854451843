import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "config/firebase";

export const useAssociatedEngineer = (clientId: string, engineerId: string) => {
  const queryClient = useQueryClient();

  const fetchEngineerAndAssignments = async (): Promise<{
    engineer: Engineer;
    assignments: ProjectAssignment[];
  }> => {
    // Fetch engineer data
    const engineerRef = doc(db, "clients", clientId, "engineers", engineerId);
    const engineerSnap = await getDoc(engineerRef);
    if (!engineerSnap.exists()) {
      throw new Error("Engineer not found");
    }
    const engineerData = {
      id: engineerSnap.id,
      ...engineerSnap.data(),
    } as Engineer;

    // Fetch engineer profile from users collection
    const userRef = doc(db, "users", engineerId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      engineerData.engineer = {
        id: userSnap.id,
        ...userSnap.data(),
      } as Freelancer;
    }

    // Fetch project assignments
    const assignmentsRef = collection(
      db,
      "clients",
      clientId,
      "projectAssignments"
    );
    const assignmentsQuery = query(
      assignmentsRef,
      where("engineerId", "==", engineerId)
    );
    const assignmentsSnap = await getDocs(assignmentsQuery);
    const assignments = assignmentsSnap.docs.map(
      (doc) => ({  id: doc.id ,...doc.data(),}) as ProjectAssignment
    );

    return { engineer: engineerData, assignments };
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["associatedEngineer", clientId, engineerId],
    queryFn: fetchEngineerAndAssignments,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });

  const updateEngineer = useMutation({
    mutationFn: async (updatedData: Partial<Engineer>) => {
      const engineerRef = doc(db, "clients", clientId, "engineers", engineerId);
      await updateDoc(engineerRef, updatedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["associatedEngineer", clientId, engineerId],
      });
    },
  });

  const removeEngineer = useMutation({
    mutationFn: async () => {
      const engineerRef = doc(db, "clients", clientId, "engineers", engineerId);
      await deleteDoc(engineerRef);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["associatedEngineer", clientId, engineerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["associatedEngineers", clientId],
      });
    },
  });

  return {
    engineer: data?.engineer,
    assignments: data?.assignments || [],
    isLoading,
    error,
    updateEngineer: updateEngineer.mutate,
    removeEngineer: removeEngineer.mutate,
  };
};
