import { getStorage, ref, getBlob, getDownloadURL } from "firebase/storage";

/**
 * Retrieves a blob URL for a file stored in Firebase Storage.
 * 
 * @param fullPath - The full path to the file in Firebase Storage.
 * @returns A Promise that resolves to the blob URL of the file.
 * @throws An error if the file cannot be retrieved or the blob URL cannot be created.
 */
export async function getDownloadableURL(fullPath: string): Promise<string> {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, fullPath);
    
    // Get the download URL from Firebase Storage
    const downloadURL = await getDownloadURL(fileRef);
    
    return downloadURL;
  } catch (error) {
    console.error("Error getting downloadable URL:", error);
    throw new Error("Failed to get downloadable URL for the file");
  }
}