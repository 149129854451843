import { collectionGroup, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase';

export const getInvoices = async (): Promise<InvoiceData[]> => {
  const invoicesSnapshot = await getDocs(collectionGroup(db, 'invoices'));
  return invoicesSnapshot.docs.map((doc): InvoiceData => ({
    id: doc.id,
    ...(doc.data() as Omit<InvoiceData, 'id'>)
  }));
};
