import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
  addDoc,
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
  setDoc,
} from "firebase/firestore";
import { db } from "config/firebase";

const clientConverter: FirestoreDataConverter<Client> = {
  toFirestore(client: WithFieldValue<Client>): DocumentData {
    const { id, projects, invoices, payments, projectAssignments, engineers, stakeholders, ...data } = client;
    return data;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): Client {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      companyName: data?.companyName,
      pointOfContactName: data?.pointOfContactName,
      email: data?.email,
      phone: data?.phone,
      location: data?.location,
      size: data?.size,
      status: data?.status,
      introductionDate: data?.introductionDate,
    };
  }
};

// Load a single client by ID with all nested collections
const loadClientById = async (clientId: string): Promise<Client | null> => {
  const docRef = doc(db, "clients", clientId).withConverter(clientConverter);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) {
    return null;
  }

  const clientData = docSnap.data();
  
  // Fetch projects
  const projectsSnapshot = await getDocs(collection(docRef, "projects"));
  clientData.projects = await Promise.all(projectsSnapshot.docs.map(async (projectDoc) => {
    const projectData = { id: projectDoc.id, ...projectDoc.data() } as Project;
    
    // Fetch consulting services agreements for each project
    const csaSnapshot = await getDocs(collection(projectDoc.ref, "consultingServicesAgreements"));
    projectData.consultingServicesAgreements = csaSnapshot.docs.map(
      (csaDoc) => ({ id: csaDoc.id, ...csaDoc.data() } as ConsultingServicesAgreement)
    );
    
    return projectData;
  }));

  // Fetch invoices
  const invoicesSnapshot = await getDocs(collection(docRef, "invoices"));
  clientData.invoices = invoicesSnapshot.docs.map(
    (invoiceDoc) => ({ id: invoiceDoc.id, ...invoiceDoc.data() } as InvoiceData)
  );

  // Fetch payments
  const paymentsSnapshot = await getDocs(collection(docRef, "payments"));
  clientData.payments = paymentsSnapshot.docs.map(
    (paymentDoc) => ({ id: paymentDoc.id, ...paymentDoc.data() } as Payment)
  );

  // Fetch project assignments
  const projectAssignmentsSnapshot = await getDocs(collection(docRef, "projectAssignments"));
  clientData.projectAssignments = projectAssignmentsSnapshot.docs.map(
    (paDoc) => ({ id: paDoc.id, ...paDoc.data() } as ProjectAssignment)
  );

  // Fetch engineers
  const engineersSnapshot = await getDocs(collection(docRef, "engineers"));
  clientData.engineers = await Promise.all(engineersSnapshot.docs.map(async (engineerDoc) => {
    const engineerData = engineerDoc.data();
    const freelancerDoc = await getDoc(doc(db, "users", engineerData.id));
    const freelancerData = freelancerDoc.data() as Freelancer;
    return {
      id: engineerDoc.id,
      associationDate: engineerData.associationDate,
      status: engineerData.status,
      engineer: {
        ...freelancerData,
        id: freelancerDoc.id,
      },
    } as Engineer;
  }));

  // Fetch stakeholders
  const stakeholdersSnapshot = await getDocs(collection(docRef, "stakeholders"));
  clientData.stakeholders = stakeholdersSnapshot.docs.map(
    (stakeholderDoc) => ({ id: stakeholderDoc.id, ...stakeholderDoc.data() } as { id: string; associationDate: string; stakeholder?: Stakeholder })
  );

  return clientData;
};

export const useAdminClient = (clientId: string) => {
  const queryClient = useQueryClient();

  // Load a single client (GET)
  const { data: client, isLoading, isError }: UseQueryResult<Client | null, Error> = useQuery<Client | null, Error>({
    queryKey: ["client", clientId],
    queryFn: () => loadClientById(clientId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Update a client (PUT)
  const updateClient: UseMutationResult<void, Error, Partial<Client>> = useMutation<void, Error, Partial<Client>>({
    mutationFn: async (updatedClient: Partial<Client>) => {
      const clientRef = doc(db, "clients", clientId).withConverter(clientConverter);
      await updateDoc(clientRef, updatedClient);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Delete a client (DELETE)
  const deleteClient: UseMutationResult<void, Error, void> = useMutation<void, Error, void>({
    mutationFn: async () => {
      await deleteDoc(doc(db, "clients", clientId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Add a new nested document
  const addNestedDocument = useMutation({
    mutationFn: async ({ collectionName, data }: { collectionName: string; data: any }) => {
      const clientRef = doc(db, "clients", clientId);
      const nestedCollectionRef = collection(clientRef, collectionName);
      const docRef = doc(nestedCollectionRef, data.id);
      await setDoc(docRef, data);
      return docRef;
    },
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId, collectionName] });
    },
  });

  // Update a nested document
  const updateNestedDocument = useMutation({
    mutationFn: async ({ collectionName, docId, data }: { collectionName: string; docId: string; data: any }) => {
      const docRef = doc(db, "clients", clientId, collectionName, docId);
      await updateDoc(docRef, data);
    },
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId, collectionName] });
    },
  });

  // Delete a nested document
  const deleteNestedDocument = useMutation({
    mutationFn: async ({ collectionName, docId }: { collectionName: string; docId: string }) => {
      const docRef = doc(db, "clients", clientId, collectionName, docId);
      await deleteDoc(docRef);
    },
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId, collectionName] });
    },
  });

  // Get all documents from a nested collection
  const getNestedDocuments = async (collectionName: string) => {
    const clientRef = doc(db, "clients", clientId);
    const nestedCollectionRef = collection(clientRef, collectionName);
    const snapshot = await getDocs(nestedCollectionRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  return {
    client,
    isLoading,
    isError,
    updateClient,
    deleteClient,
    addNestedDocument,
    updateNestedDocument,
    deleteNestedDocument,
    getNestedDocuments,
  };
};
