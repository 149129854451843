import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';
import { Snackbar, Alert, CircularProgress, Box } from '@mui/material';

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    severity?: 'error' | 'warning' | 'info' | 'success',
    size?: 'small' | 'medium' | 'large',
    position?: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'right' | 'center';
    },
    loading?: boolean,
    loadingMessage?: string,
  ) => void;
  updateSnackbar: (updates: Partial<SnackbarState>) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  size: 'small' | 'medium' | 'large';
  position: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right' | 'center';
  };
  loading?: boolean;
  loadingMessage?: string;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info',
    size: 'medium',
    position: { vertical: 'top', horizontal: 'right' },
    loading: false,
    loadingMessage: '',
  });

  const showSnackbar = useCallback(
    (
      message: string,
      severity: 'error' | 'warning' | 'info' | 'success' = 'info',
      size: 'small' | 'medium' | 'large' = 'medium',
      position: {
        vertical: 'top' | 'bottom';
        horizontal: 'left' | 'right' | 'center';
      } = { vertical: 'top', horizontal: 'right' },
      loading: boolean = false,
      loadingMessage: string = '',
    ) => {
      setSnackbar({ open: true, message, severity, size, position, loading, loadingMessage });
    },
    [],
  );

  const updateSnackbar = useCallback(
    (updates: Partial<SnackbarState>) => {
      setSnackbar((prev) => ({ ...prev, ...updates }));
    },
    [],
  );

  const hideSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const sizeStylesSnackbar = {
    small: { width: '100%' },
    medium: { width: '100%', maxWidth: '300px' },
    large: { width: '100%', maxWidth: '600px' },
  };

  const sizeStylesAlert = {
    small: { width: '100%' },
    medium: { width: '100%', fontSize: '1rem', padding: '12px' },
    large: { width: '100%', fontSize: '1.25rem', padding: '16px' },
  };

  const handleClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const autoHideDuration = snackbar.loading ? null : 6000;

  return (
    <SnackbarContext.Provider value={{ showSnackbar, updateSnackbar, hideSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={snackbar.position}
        sx={sizeStylesSnackbar[snackbar.size]}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          sx={{ ...sizeStylesAlert[snackbar.size], display: 'flex', alignItems: 'center' }}
        >
          {snackbar.loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress
                size={20}
                sx={{ marginRight: '10px', color: 'inherit' }}
              />
              {snackbar.loadingMessage || 'Loading...'}
            </Box>
          ) : (
            snackbar.message
          )}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
