import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Grid } from "@mui/material";
import AssociatedStakeholders from "./AssociatedStakeholders/AssociatedStakeholders";
import AssociatedEngineers from "./AssociatedEngineers/AssociatedEngineers";
import AssociatedProjects from "./AssociatedProjects/AssociatedProjects";
import AssociatedPayments from "./AssociatedPayments/AssociatedPayments";
import { useAdminClient } from "hooks/admin/useAdminClient";
import ClientDetails from "./ClientDetails/ClientDetails";

const ClientOverview: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();

  if (!clientId) {
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h5">No client found</Typography>
        </Box>
      </>
    );
  }

  const { client, isLoading, isError } = useAdminClient(clientId);

  if (isLoading) {
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (isError || !client) {
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h5">Error loading client data</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <ClientDetails client={client} clientId={clientId} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AssociatedProjects client={client} />
        </Grid>
        <Grid item xs={12}>
          <AssociatedEngineers client={client} />
        </Grid>
        <Grid item xs={12}>
          <AssociatedPayments client={client} />
        </Grid>
        <Grid item xs={12}>
          <AssociatedStakeholders client={client} />
        </Grid>
      </Grid>
    </>
  );
};

export default ClientOverview;
