import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "contexts/AuthContext";
import {
  updateProjectAssignment
} from "core/projectAssignments";
import { getProjectName } from "core/projects";
import { getCSAName } from "core/csa";
import { getUserProfile } from "core/users";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import { getClientCompanyName } from "core/clients";
import AgreementToSignCard from "components/ProjAssToSignCard";
import { sendMailTemplate } from "core/mail";
import { PASignedByAdminEngineerUpdateProps } from "core/mail/mailTemplates/paSignedByAdminEngineerUpdate";
import { useAdminSignAgreements } from "hooks/admin";

declare global {
  interface Window {
    eversign: any;
  }
}

const AdminSignAgreements: React.FC = () => {
  const { userInfo } = useAuth();
  const [assignments, setAssignments] = useState<ProjectAssignment[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedAssignment, setSelectedAssignment] =
    useState<ProjectAssignment | null>(null);
  const [showWarning, setShowWarning] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    isLoaded: eversignSDKLoaded,
    error: eversignSDKError,
    initializeSDK,
  } = useEversignSDK();
  const [sdkError, setSdkError] = useState<string | null>(null);
  const [signingError, setSigningError] = useState<string | null>(null);
  const [showEngineerSignatureDialog, setShowEngineerSignatureDialog] =
    useState(false);
  const [reminderSent, setReminderSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { data: signingAssignments, isLoading, isError } = useAdminSignAgreements();

  useEffect(() => {
    
    
    
  }, []);

  useEffect(() => {
    
    
    
  }, [userInfo, eversignSDKLoaded]);

  useEffect(() => {
    
    
    

    if (eversignSDKError) {
      console.error("Eversign SDK failed to load:", eversignSDKError);
      setSdkError(eversignSDKError.message);
    }
  }, [eversignSDKLoaded, eversignSDKError]);

  useEffect(() => {
    const fetchAssignments = async () => {
      if (isLoading) {
        setLoading(true);
        return;
      }

      if (isError) {
        setError("Error fetching assignments. Please try again later.");
        setLoading(false);
        return;
      }

      if (signingAssignments) {
        try {
          

          const enrichedAssignments = await Promise.all(
            signingAssignments.map(async (assignment) => {
              const projectName = await getProjectName(
                assignment.clientId,
                assignment.projectId
              );
              const csaName = assignment.csaId
                ? await getCSAName(
                    assignment.clientId,
                    assignment.projectId,
                    assignment.csaId
                  )
                : "";
              const freelancer = await getUserProfile(assignment.engineerId);
              const clientCompanyName = await getClientCompanyName(
                assignment.clientId
              );
              return {
                ...assignment,
                projectName,
                csaName,
                freelancerName:
                  freelancer?.firstName + " " + freelancer?.lastName ||
                  "Unknown Freelancer",
                clientName: clientCompanyName || "Unknown Client",
              };
            })
          );

          
          setAssignments(enrichedAssignments);
        } catch (error) {
          console.error("Error enriching assignments:", error);
          setError("Error processing assignments. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAssignments();
  }, [signingAssignments, isLoading, isError]);

  useEffect(() => {
    if (selectedAssignment && containerRef.current && eversignSDKLoaded) {
      if (window.eversign && window.eversign.open) {
        window.eversign.open({
          url: selectedAssignment.eversignAdminDocumentEmbedUrl,
          containerID: containerRef.current.id,
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
          events: {
            loaded: () => {
              
            },
            signed: async () => {
              
              await handleAgreementSigned();
            },
            declined: () => {
              
              setSelectedAssignment(null);
            },
            error: (error: any) => {
              console.error("Eversign error:", error);
              if (error === "Document not ready to be signed.") {
                setSigningError(
                  "This document is awaiting admin signature. Please try again later."
                );
              } else {
                setSigningError(`An error occurred: ${error}`);
              }
              setSelectedAssignment(null);
            },
          },
        });
      } else {
        console.error("Eversign SDK not properly loaded");
        setSdkError(
          "Eversign SDK failed to load properly. Please try refreshing the page."
        );
      }
    }
  }, [selectedAssignment, eversignSDKLoaded]);

  const handleCardClick = (assignment: ProjectAssignment) => {
    if (assignment.everSignDocumentStatus === "awaiting_engineer_signature") {
      setShowEngineerSignatureDialog(true);
    } else {
      setSelectedAssignment(assignment);
    }
  };

  const handleBack = () => {
    setShowWarning(true);
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirmBack = () => {
    setShowWarning(false);
    setSelectedAssignment(null);
  };

  const handleAgreementSigned = () => {
    if (selectedAssignment) {
      updateProjectAssignment(
        selectedAssignment.clientId,
        selectedAssignment.id,
        {
          everSignDocumentStatus: "awaiting_engineer_signature",
          status: "signing",
        }
      )
        .then(() => {
          console.log(
            "Agreement signed and status updated to awaiting_engineer_signature"
          );
          // Refresh the assignments list
          const updatedAssignments = assignments.filter(
            (a) => a.id !== selectedAssignment.id
          );
          setAssignments(updatedAssignments);
          setSelectedAssignment(null);

          // Get engineer's email and project name
          return Promise.all([
            getUserProfile(selectedAssignment.engineerId),
            getProjectName(selectedAssignment.clientId, selectedAssignment.projectId)
          ]);
        })
        .then(([userProfile, projectName]) => {
          if (!userProfile || typeof userProfile === 'string') {
            console.error("No valid user profile found for the engineer");
            throw new Error("No valid user profile found for the engineer");
          }

          if (
            selectedAssignment.freelancerName &&
            selectedAssignment.projectName &&
            selectedAssignment.eversignFreelancerDocumentEmbedUrl
          ) {
            return sendMailTemplate(
              userProfile.email,
              `${userProfile.firstName}, your agreement has been signed by the admin. Please review and sign the document.`,
              "paSignedByAdminEngineerUpdate",
              {
                engineerName: `${userProfile.firstName} ${userProfile.lastName}`,
                projectName: projectName,
                // using this url will allow them to sign the document without logging into the portal, it takes them directly to the document
                // url: selectedAssignment.eversignFreelancerDocumentEmbedUrl,
                  url: 'https://portal.freetech.co/sign-agreement',
              } as PASignedByAdminEngineerUpdateProps
            );
          }
        })
        .then(() => {
          
        })
        .catch((error) => {
          console.error(
            "Error updating assignment status or sending notification:",
            error
          );
          // Optionally, you can set an error state here to display to the user
        });
    }
  };

  const handleCloseSigningError = () => {
    setSigningError(null);
  };

  const handleCloseEngineerSignatureDialog = () => {
    setShowEngineerSignatureDialog(false);
  };

  const handleSendReminder = async () => {
    if (selectedAssignment) {
      try {
        const userProfile = await getUserProfile(selectedAssignment.engineerId);
        const projectName = await getProjectName(selectedAssignment.clientId, selectedAssignment.projectId);

        if (!userProfile || typeof userProfile === 'string') {
          throw new Error("No valid user profile found for the engineer");
        }

        await sendMailTemplate(
          userProfile.email,
          `Reminder: Please sign the agreement for ${projectName}`,
          "paSignedByAdminEngineerUpdate",
          {
            engineerName: `${userProfile.firstName} ${userProfile.lastName}`,
            projectName: projectName,
            url: 'https://portal.freetech.co/sign-agreement',
          } as PASignedByAdminEngineerUpdateProps
        );

        setReminderSent(true);
        
      } catch (error) {
        console.error("Error sending reminder email:", error);
        // Optionally, you can set an error state here to display to the user
      }
    }
  };

  if (loading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading assignments...
          </Typography>
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </Box>
      </>
    );
  }

  if (sdkError) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Typography variant="h6" color="error" gutterBottom>
            {sdkError}
          </Typography>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        </Box>
      </>
    );
  }

  
  
  if (selectedAssignment) {
    return (
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
            Back
          </Button>
          <Typography variant="h6">
            Sign Agreement: {selectedAssignment.projectName}
          </Typography>
        </Box>
        <Box ref={containerRef} id="eversign-container" sx={{ flexGrow: 1 }} />
        <Dialog
          open={showWarning}
          onClose={handleCloseWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Incomplete Agreement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You haven't completed signing the agreement. Are you sure you want
              to go back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWarning}>Cancel</Button>
            <Button onClick={handleConfirmBack} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Agreements to Sign
        </Typography>
        {assignments.length === 0 ? (
          <Typography>No agreements to sign at the moment.</Typography>
        ) : (
          <Grid container spacing={3}>
            {assignments.map((assignment) => (
              <Grid item xs={12} sm={6} md={4} key={assignment.id}>
                <AgreementToSignCard
                  assignment={assignment}
                  onClick={() => handleCardClick(assignment)}
                  isAdminView={true}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Dialog
        open={!!signingError}
        onClose={handleCloseSigningError}
        aria-labelledby="signing-error-dialog-title"
        aria-describedby="signing-error-dialog-description"
      >
        <DialogTitle id="signing-error-dialog-title">
          {"Signing Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="signing-error-dialog-description">
            {signingError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSigningError} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEngineerSignatureDialog}
        onClose={handleCloseEngineerSignatureDialog}
        aria-labelledby="engineer-signature-dialog-title"
        aria-describedby="engineer-signature-dialog-description"
      >
        <DialogTitle id="engineer-signature-dialog-title">
          {"Awaiting Engineer Signature"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="engineer-signature-dialog-description">
            This agreement is currently awaiting the engineer's signature. You
            cannot sign it at this time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSendReminder}
            color="primary"
            disabled={reminderSent}
          >
            {reminderSent ? "Reminder Sent" : "Send Reminder"}
          </Button>
          <Button
            onClick={handleCloseEngineerSignatureDialog}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminSignAgreements;