import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { sendRequestedUserEmail } from './sendRequestedUserEmail';

interface SignUpCredentials {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export const signUpAndSendEmail = async (credentials: SignUpCredentials) => {
  const auth = getAuth();

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, credentials.email, credentials.password);
    const user = userCredential.user;
    
    await setDoc(doc(db, `users/${user.uid}`), {
      email: credentials.email,
      firstName: credentials.firstName,
      lastName: credentials.lastName,
      user_type: 'non-admin',
      is_active: false,
      email_verified: false,
    });

    await sendEmailVerification(user);

    try {
      await sendRequestedUserEmail(credentials.email, credentials.firstName, credentials.lastName);
    } catch (error) {
      console.error('Failed to send email to user', error);
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};