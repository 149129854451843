import React, { memo } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { LineChart } from '@mui/x-charts/LineChart';
import { useFreelancerHomeStatistics } from 'hooks/freelancer/useFreelancerHomeStatistics';

interface EarningsChartProps {
  assignments: ProjectAssignment[];
  selectedYear: number;
  onYearChange: (event: SelectChangeEvent<number>) => void;
}

const EarningsChart: React.FC<EarningsChartProps> = memo(({ selectedYear, onYearChange }) => {
  const { monthlyEarnings } = useFreelancerHomeStatistics(selectedYear);
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear - i);

  return (
    <Box>
      <FormControl sx={{ mb: 2, minWidth: 120 }}>
        <InputLabel id="year-select-label">Year</InputLabel>
        <Select
          labelId="year-select-label"
          value={selectedYear}
          label="Year"
          onChange={onYearChange}
        >
          {yearOptions.map(year => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ height: 300, mb: 4 }}>
        <Typography variant="h6" gutterBottom align="center">
          Monthly Earnings for {selectedYear}
        </Typography>
        <LineChart
          xAxis={[{ 
            data: monthlyEarnings.map(item => item.month),
            scaleType: 'band',
          }]}
          yAxis={[{ 
            label: 'Earnings ($)'
          }]}
          series={[
            {
              data: monthlyEarnings.map(item => item.earnings),
              area: true,
            },
          ]}
        />
      </Box>
    </Box>
  );
});

export default EarningsChart;
