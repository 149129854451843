import {  getHeaders } from 'core';
import { Transaction } from 'increase/resources';
import { increaseFunctionUrls } from '../increaseFunctionUrls';

export const listAccountTransactions = async (accountId: string): Promise<Transaction[]> => {
  try {
    const response = await fetch(increaseFunctionUrls.accounts.listAccountTransactions.url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ accountId }),
      // body: encryptPayload({ accountId }),
    });

    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.transactions.length > 0) { 
      return data.transactions;
    } else {
      return [];
    }

  } catch (error) {
    console.error('Error in listAccountTransactions:', error);
    throw error;
  }
};
